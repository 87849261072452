import {Environment} from './environment-interface';

export const environment:Environment = {
production: true,
stripe_key: 'pk_test_51IHSVIEV0dtmzNZM98cibCWzgfjGKKZgBOApIKpFJurlGwRhyzPHCwgeJfI6MA0hqFDHzUgpDcuoBQPkaRLFrHeh00raYHoRDt',
router: {
    enableTracing: true
},
security: {
    endpointUrl: 'http://localhost:8082/auth-api',
    enableAuthOnHttp:true
},
logCentral: {
    isEnable: true,
    handlerLogUrl: 'https://www.diakabana.com/mediastore/auth-api/logs'
    //handlerLogUrl: 'http://localhost:8082/auth-api/logs'
},
apiUrl : 'https://www.diakabana.com/mediastore/',
//apiUrl : 'http://localhost:8082',
firebase: {
     apiKey: "AIzaSyCBb5c-J-ALb5-iuBDX8k9eGe95tpRyEtg",
        authDomain: "fir-rtc-5a045.firebaseapp.com",
        databaseURL: "https://fir-rtc-5a045.firebaseio.com",
        projectId: "fir-rtc-5a045",
        storageBucket: "fir-rtc-5a045.appspot.com",
        messagingSenderId: "639069495779",
        appId: "1:639069495779:web:7beeffb54b768cf01980d6"
  }

};

import 'zone.js/dist/zone-error';