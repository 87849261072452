import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import { environment } from '@env/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser!=null && currentUser.id_token!=null;
       // alert('isLoggedIn='+isLoggedIn);
        //Authorization: `Bearer ${currentUser.id_token}`

       // alert(request.url);

        const isApiUrl = request.url.startsWith(`${environment.apiUrl}`);
       // alert('isApiUrl='+isApiUrl);
        if (isLoggedIn && isApiUrl) {
           // console.log('isLoggedIn='+isLoggedIn);
              var user:any=JSON.parse(localStorage.getItem('currentUser'));

                                        //alert('JwtInterceptor user token='+user.id_token);
            request = request.clone({
                setHeaders: {
               // 'Content-Type':  'application/x-www-form-urlencoded',
                //    'Content-Type':  'multipart/form-data',
                                                                                                      'Access-Control-Allow-Credentials' : 'true',
                                                                                                      'Access-Control-Allow-Origin': '*',
                                                                                                      'Referrer-Policy': 'no-referrer',
                                                                                                      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                                                                                      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                                                                                                      'Authorization': user.id_token

                }
            });
        }

        return next.handle(request);
    }
}