import { MediaModel } from './media-model';
export class PlayListModel {
playListTitle:string;
	playListIconUrl:string;
	playListFolder:string;
	playListDateDebut:string;
	playListDateFin:string;
	position:number;
    medias: MediaModel[];
}
