import { Component, Renderer2,ViewChild, ElementRef,  EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './_services/authentication.service';
import { User } from './_models/user';
import { Token } from './_models/token.model';
import { PlayList } from './_models/playlist';
import { PlayListModel } from './_models/playlist-model';
import { DomaineFormationModel } from './_models/domaine.model';
import { MediaModel } from './_models/media-model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SignupComponent} from './signup/signup.component';
import {RegistrationFormComponent} from './registration-form/registration-form.component';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { UserService } from './_services/user.service';
import { UserMediasService } from './_services/user-medias.service';
import { FormationService } from './_services/formation.service';
import { toArray } from 'rxjs/operators';
import {ToastService} from './_services/toast.service';
import { catchError } from 'rxjs/operators';


@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    currentUser: User;
    currentPlayLists: PlayListModel[];
    currentDomaines: DomaineFormationModel[];
    currentPlayListsIndex:number=0;
    currentVideos: Array<{}>;
     currentTopics:Array<{}>;
             formationTopics:EventEmitter<Array<{}>> = new EventEmitter<Array<{}>>();
    articlesEmitted:Array<{}> = new Array<{}>();
    formationTopicsEmitted:Array<{}> = new Array<{}>();
    isLoggedIn:boolean;
    loginForm: FormGroup;
        searchForm: FormGroup;
        loading = false;
        submitted = false;
        returnUrl: string;
        error = '';
        playListModel:PlayListModel;
        playlist:Array<{}>;
        slides:Array<{}>;
        youtubePlaylist:PlayList;
        currentVideo:any;
@ViewChild('player__video') div: ElementRef;
 subjId:string;
         rubId:string;


         /*static params = {
            subjectId:'sujId',
            rubriqueId:'rubId',
            domaineId:'domaineId'

         };*/


/*dialogSubmitSubscription = this.dialogRef.componentInstance.submitClicked
        .subscribe(result => {
          console.log('Got the data!', result);
          this.dialogSubmitSubscription.unsubscribe();
        });*/




    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private renderer: Renderer2,
        private http: HttpClient,
        private userService: UserService,
        private userMediasService: UserMediasService,
         private formationService: FormationService,
        private toastService: ToastService,
        private matDialog: MatDialog

    ) {
        //this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        //this.currentUser = this.getCurrentUser();
        //this.isLoggedIn=this.currentUser!=null;
        this.isLoggedIn= this.getCurrentUser();

    }



    logout() {
        this.authenticationService.logout();
        //this.router.navigate(['/login']);
    }

    getArticles(event:Array<{}>){
        //alert(event);
        if(event){
            this.articlesEmitted=event;
            console.log("this.articlesEmitted="+this.articlesEmitted);


        }

          }
          

           getFormationTopics(event:Array<{}>){
                  //alert(event);
                  if(event){
                      this.formationTopicsEmitted=event;

          
          
                  }
          
                    }

          getFormationArticles(event:Array<{}>){
                  //alert(event);
                  if(event){
                      this.formationTopicsEmitted=event;
                      console.log("this.formationTopicsEmitted="+this.formationTopicsEmitted);


                  }

                    }




          getCurrentUser(){
                    var tokenExists:boolean=localStorage&&localStorage.getItem('currentUser')!=null;
                    /*var result:User=null;
                    if(tokenExists){
                      this.userService.getUserByToken().subscribe((x) => {
                                          this.currentUser=x;
                                            return this.currentUser;
                                         },
                                         catchError((error) => {
                                         //this.toastService.errorHandlerWithToast(error);
                                         this.currentUser=null;
                                         console.log(error);
                                         alert(error);
                                         return null;
                                         }
                                          ),

                                      );
                    }*/
                    //return this.currentUser;
                    return this.isLoggedIn;
                    }

    ngOnInit() {



                this.searchForm = this.formBuilder.group({
                            searchQ: ['', Validators.required]

                        });

                        //this.currentUser = this.getCurrentUser();

                //this.isLoggedIn=this.currentUser!=null;
                this.isLoggedIn= this.getCurrentUser();
              // alert('currentUser :'+this.currentUser);
               // console.log('currentUser :'+this.currentUser);
                //alert('isLoggedIn :'+this.isLoggedIn);

                // reset login status
               // alert('Warning logout');
               //alert('localStorage='+localStorage.getItem('currentUser'));
               //alert('Warning logout');
               this.loadDomaines();
               //TODO A DECOMMENTER
             //this.loadPlayLists();
               if(!this.isLoggedIn){
               //alert('!isLoggedIn : logout!!!');
                //this.authenticationService.logout();
               }else{
                  //alert('isLoggedIn : loadPlayLists!!!');


               }
                // get return url from route parameters or default to '/'
                //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    //this.returnUrl= '${environment.apiUrl}/auth-api/account';
                this.slides = [
                                { src: 'https://www.diakabana.com/medias/assets/images/FORBES 30.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/Abojeyi at Work.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/Waterloo.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/Waterloo University.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/Young Abojeyi laughing.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/Bookneto demo.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/bookneto demo2.png'},
                                { src: 'https://www.diakabana.com/medias/assets/images/bookneto demo3.png'}

                              //{ src: 'https://images.radio-canada.ca/q_auto,w_1250/v1/ici-info/16x9/huawei-television-intelligente-harmony-os-george-zhao.jpg'},
                              //{ src: 'https://images.frandroid.com/wp-content/uploads/2019/08/honor-vision-4.png' },
                              //{ src: 'https://images.frandroid.com/wp-content/uploads/2019/08/honor-vision-2.png' },
                              //{ src: 'https://www.larevuedudigital.com/wp-content/uploads/2019/08/Huawei-HarmonyOS-BF2-.jpg' }
                            ];
                //this.playlist = [
                if(!this.isLoggedIn){
                this.playlist = [
                                        {
                                          mediaTitle: 'Friends-Amii-Stewart',
                                          mediaUrl:
                                            '/medias/assets/videos/amy-stewart/Friends-Amii-Stewart.mp4',
                                          thumbnail:
                                            '/medias/assets/videos/denzel-washington_jpg_200x380_q95.JPG',
                                            lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                            lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                            lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                            lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                            lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                            lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                            lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                            lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                            lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                            lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                            lyrics_p11 : 'Show me how sweet and easy it can be',
                                            lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                            lyrics_p13 : 'And let us stick together and make it happen',
                                            lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                            lyrics_p15 : 'I won\'t think it over again',
                                            lyrics_p16 : 'Je n\'y repenserai plus',
                                            lyrics_p17 : 'Take you to the top',
                                            lyrics_p18 : 'Te prendre au sommet',
                                            lyrics_p19 : 'Tell me every hidden thought',
                                            lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                            lyrics_p21 : 'Come on let me love you again',
                                            lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                            lyrics_p23 : 'Do the best I can',
                                            lyrics_p24 : 'Faire du mieux que je peux',
                                            lyrics_p25 : 'Make me tremble with your breath',
                                            lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                            lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                            lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                            lyrics_p29 : 'I wanna love your body till the morning light',
                                            lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                            lyrics_p31 : 'Ooh watch me my friend',
                                            lyrics_p32 : 'Ooh regarde moi mon ami',
                                            lyrics_p33 : 'I feel you reachin\' in my mind',
                                            lyrics_p34 : 'Je te sens atteindre mon esprit',
                                            lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                            lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                            lyrics_p37 : 'I keep on trying to change my point of view again',
                                            lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                            lyrics_p39 : 'But everything it seems to be the same',
                                            lyrics_p40 : 'Mais tout semble être pareil',
                                            lyrics_p41 : 'I want you where you are now',
                                            lyrics_p42 : 'Je te veux où tu es maintenant',
                                            lyrics_p43 : 'Don\'t wanna lose you',
                                            lyrics_p44 : '',
                                            lyrics_p45 : '',
                                            lyrics_p46 : '',
                                            lyrics_p47 : '',
                                            lyrics_p48 : '',
                                            lyrics_p49 : '',
                                            lyrics_p50 : '',
                                            lyrics_p51 : '',
                                            lyrics_p52 : '',
                                            lyrics_p53 : '',
                                            lyrics_p54 : '',
                                            lyrics_p56 : '',
                                            lyrics_p57 : '',
                                            lyrics_p58 : '',
                                            lyrics_p59 : '',
                                            lyrics_p60 : '',
                                            lyrics_p61 : '',
                                            lyrics_p62 : '',
                                            lyrics_p63 : '',
                                            lyrics_p64 : '',
                                            lyrics_p65 : '',
                                            lyrics_p66 : '',
                                            lyrics_p67 : '',
                                            lyrics_p68 : '',
                                            lyrics_p69 : '',
                                            lyrics_p70 : '',
                                            lyrics_p71 : '',
                                            lyrics_p72 : '',
                                            lyrics_p73 : '',
                                            lyrics_p74 : '',
                                            show_lyrics : 'true',
                                            tracks : [
                                                {kind : 'captions',
                                                 label : 'English',
                                                  srclang : 'en',
                                                   src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                   mode : 'showing'},
                                                {
                                                kind : 'sous-titres',
                                             label : 'French',
                                              srclang : 'fr',
                                               src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                               mode : 'showing'
                                                }
                                            ]



                                        },
                                         {
                                                                                  mediaTitle: 'Baladas Romanticas 70 80 90 en InglesMix-BaladasForeverenIngles',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/BaladasRomanticas708090enInglesMix-BaladasForeverenIngles.mp4',
                                                                                    thumbnail:
                                                                                                                                '/medias/assets/videos/eddy_murphy_head_trailer.JPG',
                                                                                                                                 lyrics_p1 : 'Oceans apart day after day',
                                                                                                                                                                            lyrics_p2 : 'Séparés par des océans jour après jour',
                                                                                                                                                                            lyrics_p3 : 'And I slowly go insane',
                                                                                                                                                                            lyrics_p4 : 'Et je deviens lentement fou',
                                                                                                                                                                            lyrics_p5 : 'I hear your voice on the line',
                                                                                                                                                                            lyrics_p6 : 'J\'entends ta voix sur la ligne',
                                                                                                                                                                            lyrics_p7 : 'But it doesn\'t stop the pain',
                                                                                                                                                                            lyrics_p8 : 'Mais ça n\'arrête pas la douleur',
                                                                                                                                                                            lyrics_p9 : 'If I see you next to never',
                                                                                                                                                                            lyrics_p10 : 'Si je te vois à côté de jamais',
                                                                                                                                                                            lyrics_p11 : 'How can we say forever',
                                                                                                                                                                            lyrics_p12 : 'Comment pouvons-nous dire pour toujours',
                                                                                                                                                                            lyrics_p13 : 'Wherever you go',
                                                                                                                                                                            lyrics_p14 : 'Peu importe où tu vas',
                                                                                                                                                                            lyrics_p15 : 'Whatever you do',
                                                                                                                                                                            lyrics_p16 : 'Quoi que tu fasses',
                                                                                                                                                                            lyrics_p17 : 'I will be right here waiting for you',
                                                                                                                                                                            lyrics_p18 : 'Je serai là à t\'attendre',
                                                                                                                                                                            lyrics_p19 : 'Whatever it takes',
                                                                                                                                                                            lyrics_p20 : 'Quelque que soit ce que ça me prend',
                                                                                                                                                                            lyrics_p21 : 'Or how my heart breaks',
                                                                                                                                                                            lyrics_p22 : 'Ou comment mon cœur se brise',
                                                                                                                                                                            lyrics_p23 : 'I will be right here waiting for you',
                                                                                                                                                                            lyrics_p24 : 'Je serai là à t\'attendre',
                                                                                                                                                                            lyrics_p25 : 'I took for granted, all the times',
                                                                                                                                                                            lyrics_p26 : 'Je tenais pour acquis, tout le temps',
                                                                                                                                                                            lyrics_p27 : 'That I thought would last somehow',
                                                                                                                                                                            lyrics_p28 : 'Que je pensais durer d\'une manière ou d\'une autre',
                                                                                                                                                                            lyrics_p29 : 'I hear the laughter, I taste the tears',
                                                                                                                                                                            lyrics_p30 : 'J\'entends le rire, je goûte les larmes',
                                                                                                                                                                            lyrics_p31 : 'But I can\'t get near you now',
                                                                                                                                                                            lyrics_p32 : 'Mais je ne peux pas m\'approcher maintenant',
                                                                                                                                                                            lyrics_p33 : 'Oh, can\'t you see it baby',
                                                                                                                                                                            lyrics_p34 : 'Oh, tu ne le vois pas bébé',
                                                                                                                                                                            lyrics_p35 : 'You\'ve got me going crazy',
                                                                                                                                                                            lyrics_p36 : 'Tu me fais devenir fou',
                                                                                                                                                                            lyrics_p37 : 'Wherever you go',
                                                                                                                                                                            lyrics_p38 : 'Peu importe où tu vas',
                                                                                                                                                                            lyrics_p39 : 'Whatever you do',
                                                                                                                                                                            lyrics_p40 : 'Quoi que tu fasses',
                                                                                                                                                                            lyrics_p41 : 'I will be right here waiting for you',
                                                                                                                                                                            lyrics_p42 : 'Je serai là à t\'attendre',
                                                                                                                                                                            lyrics_p43 : 'Whatever it takes',
                                                                                                                                                                            lyrics_p44 : 'Quelque que soit ce que ça me prend',
                                                                                                                                                                            lyrics_p45 :  'Or how my heart breaks' ,
                                                                                                                                                                            lyrics_p46 :   'Ou comment mon cœur se brise',
                                                                                                                                                                            lyrics_p47 :   'I will be right here waiting for you',
                                                                                                                                                                            lyrics_p48 :   'Ou comment mon cœur se brise',
                                                                                                                                                                            lyrics_p49 :   'I wonder how we can survive',
                                                                                                                                                                            lyrics_p50 :   'Je me demande comment nous pouvons survivre',
                                                                                                                                                                            lyrics_p51 :   'This romance',
                                                                                                                                                                            lyrics_p52 :   'Cette romance',
                                                                                                                                                                            lyrics_p53 :   'But in the end if I\'m with you',
                                                                                                                                                                            lyrics_p54 :   'Mais à la fin si je suis avec toi',
                                                                                                                                                                            lyrics_p55 :   'I\'ll take the chance',
                                                                                                                                                                            lyrics_p56 :   'Je vais tenter ma chance',
                                                                                                                                                                            lyrics_p57 :   'Oh, can\'t you see it baby',
                                                                                                                                                                            lyrics_p58 :   'Oh, tu ne le vois pas bébé',
                                                                                                                                                                            lyrics_p59 :   'You\'ve got me going crazy',
                                                                                                                                                                            lyrics_p60 :   'Tu me fais devenir fou',
                                                                                                                                                                            lyrics_p61 :   'Wherever you go',
                                                                                                                                                                            lyrics_p62 :   'Peu importe où tu vas',
                                                                                                                                                                            lyrics_p63 :   'Whatever you do',
                                                                                                                                                                            lyrics_p64 :   'Quoi que tu fasses',
                                                                                                                                                                            lyrics_p65 :   'I will be right here waiting for you',
                                                                                                                                                                            lyrics_p66 :   'Je serai là à t\'attendre',
                                                                                                                                                                            lyrics_p67 :   'Whatever it takes',
                                                                                                                                                                            lyrics_p68 :   'Quelque que soit ce que ça me prend',
                                                                                                                                                                            lyrics_p69 :   'Or how my heart breaks',
                                                                                                                                                                            lyrics_p70 :   'Ou comment mon cœur se brise',
                                                                                                                                                                            lyrics_p71 :   'I will be right here waiting for you',
                                                                                                                                                                            lyrics_p72 :   'Je serai là à t\'attendre',
                                                                                                                                                                            lyrics_p73 :   'Waiting for you',
                                                                                                                                                                            lyrics_p74 :    'à t\'attendre',
                                            show_lyrics : 'true',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                                                            },
                                         {
                                                                                  mediaTitle: 'BARRY WHITE LOVE UNLIMITED-classics-videomusic-remix',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/BARRYWHITELOVEUNLIMITED-classics-videomusic-remix.mp4',
                                                                                 thumbnail:
                                                                                     'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                       lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                  lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                  lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                  lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                  lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                  lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                  lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                  lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                  lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                  lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                  lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                  lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                  lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                  lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                  lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                  lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                  lyrics_p17 : 'Take you to the top',
                                                                                                                                  lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                  lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                  lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                  lyrics_p21 : 'Come on let me love you again',
                                                                                                                                  lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                  lyrics_p23 : 'Do the best I can',
                                                                                                                                  lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                  lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                  lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                  lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                  lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                  lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                  lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                  lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                  lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                  lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                  lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                  lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                  lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                  lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                  lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                  lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                  lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                  lyrics_p41 : 'I want you where you are now',
                                                                                                                                  lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                  lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                  lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]
                                             },
                                         {
                                                                                  mediaTitle: 'Barry White Love Unlimited Orchestra-Loves-theme-(video-audio-edited-restored) HQ HD',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/BarryWhiteLoveUnlimitedOrchestra-Loves-theme-(video-audio-edited-restored)HQHD.mp4',
                                                                                   thumbnail:
                                                                                    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                     lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                lyrics_p17 : 'Take you to the top',
                                                                                                                                lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                lyrics_p21 : 'Come on let me love you again',
                                                                                                                                lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                lyrics_p23 : 'Do the best I can',
                                                                                                                                lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                lyrics_p41 : 'I want you where you are now',
                                                                                                                                lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                         },
                                         {
                                                                                  mediaTitle: 'Barry White- Cant get enough of your love baby',
                                                                                  source :
                                                                                    '/medias/assets/videos/Barry-White-Cant-get-enough-of-your-love-baby.webm',
                                                                                thumbnail:
                                                                                                                            'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                              lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                         lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                         lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                         lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                         lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                         lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                         lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                         lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                         lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                         lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                         lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                         lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                         lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                         lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                         lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                         lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                         lyrics_p17 : 'Take you to the top',
                                                                                                                                                                         lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                         lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                         lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                         lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                         lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                         lyrics_p23 : 'Do the best I can',
                                                                                                                                                                         lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                         lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                         lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                         lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                         lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                         lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                         lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                         lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                         lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                         lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                         lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                         lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                         lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                         lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                         lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                         lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                         lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                         lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                         lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                         lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                         lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                         },
                                         {
                                                                                  mediaTitle: 'Barry-White-Just-the-way-you-are-(complete)-(video-audio-edited-remastered)HQ',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Barry-White-Just-the-way-you-are-(complete)-(video-audio-edited-remastered)HQ.mp4',
                                                                                    thumbnail:
                                                                                         'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                           lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                      lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                      lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                      lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                      lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                      lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                      lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                      lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                      lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                      lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                      lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                      lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                      lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                      lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                      lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                      lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                      lyrics_p17 : 'Take you to the top',
                                                                                                                                      lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                      lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                      lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                      lyrics_p21 : 'Come on let me love you again',
                                                                                                                                      lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                      lyrics_p23 : 'Do the best I can',
                                                                                                                                      lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                      lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                      lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                      lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                      lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                      lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                      lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                      lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                      lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                      lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                      lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                      lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                      lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                      lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                      lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                      lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                      lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                      lyrics_p41 : 'I want you where you are now',
                                                                                                                                      lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                      lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                      lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                     },
                                         {
                                                                                  mediaTitle: 'Barry-White-Practice-What-You-Preach',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Barry-White-Practice-What-You-Preach.mp4',
                                                                                    thumbnail:
                                                                                     'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                       lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                  lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                  lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                  lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                  lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                  lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                  lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                  lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                  lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                  lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                  lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                  lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                  lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                  lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                  lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                  lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                  lyrics_p17 : 'Take you to the top',
                                                                                                                                  lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                  lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                  lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                  lyrics_p21 : 'Come on let me love you again',
                                                                                                                                  lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                  lyrics_p23 : 'Do the best I can',
                                                                                                                                  lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                  lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                  lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                  lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                  lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                  lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                  lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                  lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                  lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                  lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                  lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                  lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                  lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                  lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                  lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                  lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                  lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                  lyrics_p41 : 'I want you where you are now',
                                                                                                                                  lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                  lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                  lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                 },
                                         {
                                                                                  mediaTitle: 'Barry White Wins Favorite Male Soul Artist AMA 1976',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Barry-White-Wins-Favorite-Male-Soul-Artist-AMA-1976.mp4',
                                                                                   thumbnail:
                                                                                    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                      lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                 lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                 lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                 lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                 lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                 lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                 lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                 lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                 lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                 lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                 lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                 lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                 lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                 lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                 lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                 lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                 lyrics_p17 : 'Take you to the top',
                                                                                                                                 lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                 lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                 lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                 lyrics_p21 : 'Come on let me love you again',
                                                                                                                                 lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                 lyrics_p23 : 'Do the best I can',
                                                                                                                                 lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                 lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                 lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                 lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                 lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                 lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                 lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                 lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                 lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                 lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                 lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                 lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                 lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                 lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                 lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                 lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                 lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                 lyrics_p41 : 'I want you where you are now',
                                                                                                                                 lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                 lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                 lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                },
                                         {
                                                                                  mediaTitle: 'Bee Gees more than a woman - Dancing Saturday Night Fever',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Bee-Gees-more-than-a-woman -Dancing-Saturday-Night-Fever.mp4',
                                                                                    thumbnail:
                                                                                      'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                        lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                   lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                   lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                   lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                   lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                   lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                   lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                   lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                   lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                   lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                   lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                   lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                   lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                   lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                   lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                   lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                   lyrics_p17 : 'Take you to the top',
                                                                                                                                   lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                   lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                   lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                   lyrics_p21 : 'Come on let me love you again',
                                                                                                                                   lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                   lyrics_p23 : 'Do the best I can',
                                                                                                                                   lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                   lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                   lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                   lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                   lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                   lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                   lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                   lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                   lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                   lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                   lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                   lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                   lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                   lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                   lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                   lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                   lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                   lyrics_p41 : 'I want you where you are now',
                                                                                                                                   lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                   lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                   lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                  },
                                         {
                                                                                  mediaTitle: 'Billy Paul - Your Song',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Billy-Paul-Your-Song.mp4',
                                                                                    thumbnail:
                                                                                     'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                       lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                  lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                  lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                  lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                  lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                  lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                  lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                  lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                  lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                  lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                  lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                  lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                  lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                  lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                  lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                  lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                  lyrics_p17 : 'Take you to the top',
                                                                                                                                  lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                  lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                  lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                  lyrics_p21 : 'Come on let me love you again',
                                                                                                                                  lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                  lyrics_p23 : 'Do the best I can',
                                                                                                                                  lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                  lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                  lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                  lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                  lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                  lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                  lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                  lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                  lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                  lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                  lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                  lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                  lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                  lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                  lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                  lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                  lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                  lyrics_p41 : 'I want you where you are now',
                                                                                                                                  lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                  lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                  lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                 },
                                         {
                                                                                  mediaTitle: 'CNS CONGOLAISE - Okongo Nicolas reconnait la responsabilité du CMP sur les assassinats de mars 1977',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/CNS-Okongo-Nicolas-reconnait-la-responsabilité-du-CMP-sur-les assassinats-de-mars-1977.mp4',
                                                                                    thumbnail:
                                                                                       'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                         lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                    lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                    lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                    lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                    lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                    lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                    lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                    lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                    lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                    lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                    lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                    lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                    lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                    lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                    lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                    lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                    lyrics_p17 : 'Take you to the top',
                                                                                                                                    lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                    lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                    lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                    lyrics_p21 : 'Come on let me love you again',
                                                                                                                                    lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                    lyrics_p23 : 'Do the best I can',
                                                                                                                                    lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                    lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                    lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                    lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                    lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                    lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                    lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                    lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                    lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                    lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                    lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                    lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                    lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                    lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                    lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                    lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                    lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                    lyrics_p41 : 'I want you where you are now',
                                                                                                                                    lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                    lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                    lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                   },

                                         {
                                                                                  mediaTitle: 'Evry - Earth Wind and Fire Experience enflamme la Fête de la Musique 210613',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Evry-Earth-Wind-and-Fire-Experience-enflamme-la-Fête-de-la-Musique-210613.mp4',
                                                                                 thumbnail:
                                                                                      'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                        lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                   lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                   lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                   lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                   lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                   lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                   lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                   lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                   lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                   lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                   lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                   lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                   lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                   lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                   lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                   lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                   lyrics_p17 : 'Take you to the top',
                                                                                                                                   lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                   lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                   lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                   lyrics_p21 : 'Come on let me love you again',
                                                                                                                                   lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                   lyrics_p23 : 'Do the best I can',
                                                                                                                                   lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                   lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                   lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                   lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                   lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                   lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                   lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                   lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                   lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                   lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                   lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                   lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                   lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                   lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                   lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                   lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                   lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                   lyrics_p41 : 'I want you where you are now',
                                                                                                                                   lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                   lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                   lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                  },

                                         {
                                                                                  mediaTitle: 'Microsoft Build 2017 - Premier contrôleur de mouvement Mixed Reality de Microsoft',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/ezgif.com-optimize-3.mp4',
                                                                                thumbnail:
                                                                                                                            'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                              lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                         lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                         lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                         lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                         lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                         lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                         lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                         lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                         lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                         lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                         lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                         lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                         lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                         lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                         lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                         lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                         lyrics_p17 : 'Take you to the top',
                                                                                                                                                                         lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                         lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                         lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                         lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                         lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                         lyrics_p23 : 'Do the best I can',
                                                                                                                                                                         lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                         lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                         lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                         lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                         lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                         lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                         lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                         lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                         lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                         lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                         lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                         lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                         lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                         lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                         lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                         lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                         lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                         lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                         lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                         lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                         lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                                                                                        },

                                         {
                                                                                  mediaTitle: 'Fall-In-Love-With-Me-Earth-Wind-And-Fire-1983',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Fall-In-Love-With-Me-Earth Wind-And-Fire-1983.mp4',
                                                                                   thumbnail:
                                                                                                                               'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                 lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                            lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                            lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                            lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                            lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                            lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                            lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                            lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                            lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                            lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                            lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                            lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                            lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                            lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                            lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                            lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                            lyrics_p17 : 'Take you to the top',
                                                                                                                                                                            lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                            lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                            lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                            lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                            lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                            lyrics_p23 : 'Do the best I can',
                                                                                                                                                                            lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                            lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                            lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                            lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                            lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                            lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                            lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                            lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                            lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                            lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                            lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                            lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                            lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                            lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                            lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                            lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                            lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                            lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                            lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                            lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                            lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                                },
                                                {
                                                                                  mediaTitle: 'CNS CONGOLAISE - Intervention de Thystère Tchikaya',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Intervention-de-Thystère-3.wmv.webm',
                                                                                  thumbnail:
                                                                                                                              'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                           lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                           lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                           lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                           lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                           lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                           lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                           lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                           lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                           lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                           lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                           lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                           lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                           lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                           lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                           lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                           lyrics_p17 : 'Take you to the top',
                                                                                                                                                                           lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                           lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                           lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                           lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                           lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                           lyrics_p23 : 'Do the best I can',
                                                                                                                                                                           lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                           lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                           lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                           lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                           lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                           lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                           lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                           lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                           lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                           lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                           lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                           lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                           lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                           lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                           lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                           lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                           lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                           lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                           lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                           lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                           lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                             tracks : [
                                                                                                                 {kind : 'captions',
                                                                                                                  label : 'English',
                                                                                                                   srclang : 'en',
                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                    mode : 'showing'},
                                                                                                                 {
                                                                                                                 kind : 'sous-titres',
                                                                                                              label : 'French',
                                                                                                               srclang : 'fr',
                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                mode : 'showing'
                                                                                                                 }
                                                                                                             ]

                                           },
                                         {
                                                                                  mediaTitle: 'J\'ai vu l\'Eau vive,  chorale saint corbinien Cathédrale EVRY',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/J-ai-vu-l-eau-vive-chorale-saint-corbinien-Cathedrale-d-evry.webm',
                                                                                                                                                                                          thumbnail:
                                                                                                                                                                                                                                      'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                                                                                                                        lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                                                                                                                                   lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                                                                                                                                   lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                                                                                                                                   lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                                                                                                                                   lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                                                                                                                                   lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                                                                                                                                   lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                                                                                                                                   lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                                                                                                                                   lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                                                                                                                                   lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                                                                                                                                   lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                                                                                                                                   lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                                                                                                                                   lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                                                                                                                                   lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                                                                                                                                   lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                                                                                                                                   lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                                                                                                                                   lyrics_p17 : 'Take you to the top',
                                                                                                                                                                                                                                                                                   lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                                                                                                                                   lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                                                                                                                                   lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                                                                                                                                   lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                                                                                                                                   lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                                                                                                                                   lyrics_p23 : 'Do the best I can',
                                                                                                                                                                                                                                                                                   lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                                                                                                                                   lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                                                                                                                                   lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                                                                                                                                   lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                                                                                                                                   lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                                                                                                                                   lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                                                                                                                                   lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                                                                                                                                   lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                                                                                                                                   lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                                                                                                                                   lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                                                                                                                                   lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                                                                                                                                   lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                                                                                                                                   lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                                                                                                                                   lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                                                                                                                                   lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                                                                                                                                   lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                                                                                                                                   lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                                                                                                                                   lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                                                                                                                                   lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                                                                                                                                   lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                                                                                                                                   lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]
                                             },
                                         {
                                                                                  mediaTitle: 'La version des faits de Marien Ngouabi Junior sur l\'assassinat de son père',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/La-version-des-faits-de-Marien-Ngouabi-Junior-sur-l-assassinat-de-son-père.mp4',
                                                                                 thumbnail:
                                                                                                                             'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                               lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                          lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                          lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                          lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                          lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                          lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                          lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                          lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                          lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                          lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                          lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                          lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                          lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                          lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                          lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                          lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                          lyrics_p17 : 'Take you to the top',
                                                                                                                                                                          lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                          lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                          lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                          lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                          lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                          lyrics_p23 : 'Do the best I can',
                                                                                                                                                                          lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                          lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                          lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                          lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                          lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                          lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                          lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                          lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                          lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                          lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                          lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                          lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                          lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                          lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                          lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                          lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                          lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                          lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                          lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                          lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                          lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                          },
                                         {
                                                                                  mediaTitle: 'Let The Music Play - Barry White   China Black',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Let-The-Music-Play-Barry-White-China-Black.mp4',
                                                                                thumbnail:
                                                                                    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                      lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                 lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                 lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                 lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                 lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                 lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                 lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                 lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                 lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                 lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                 lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                 lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                 lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                 lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                 lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                 lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                 lyrics_p17 : 'Take you to the top',
                                                                                                                                 lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                 lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                 lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                 lyrics_p21 : 'Come on let me love you again',
                                                                                                                                 lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                 lyrics_p23 : 'Do the best I can',
                                                                                                                                 lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                 lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                 lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                 lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                 lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                 lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                 lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                 lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                 lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                 lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                 lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                 lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                 lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                 lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                 lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                 lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                 lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                 lyrics_p41 : 'I want you where you are now',
                                                                                                                                 lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                 lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                 lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                         },
                                         {
                                                                                  mediaTitle: 'Marien Ngouabi   Chronique d\'une mort annoncée',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Marien-Ngouabi-Chronique-d-une-mort-annoncee.mp4',
                                                                                  thumbnail:
                                                                                                                              'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                           lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                           lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                           lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                           lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                           lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                           lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                           lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                           lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                           lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                           lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                           lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                           lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                           lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                           lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                           lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                           lyrics_p17 : 'Take you to the top',
                                                                                                                                                                           lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                           lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                           lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                           lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                           lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                           lyrics_p23 : 'Do the best I can',
                                                                                                                                                                           lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                           lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                           lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                           lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                           lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                           lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                           lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                           lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                           lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                           lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                           lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                           lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                           lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                           lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                           lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                           lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                           lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                           lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                           lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                           lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                           lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                                                                                                                                                                                           },
                                         {
                                                                                  mediaTitle: 'Microsoft Build 2017 - Premier contrôleur de mouvement Mixed Reality de Microsoft',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/May-11-2017-08-51-15.mp4',
                                                                                       thumbnail:
                                                                                                                                   'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                     lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                                lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                                lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                                lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                                lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                                lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                                lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                                lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                                lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                                lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                                lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                                lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                                lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                                lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                                lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                                lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                                lyrics_p17 : 'Take you to the top',
                                                                                                                                                                                lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                                lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                                lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                                lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                                lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                                lyrics_p23 : 'Do the best I can',
                                                                                                                                                                                lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                                lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                                lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                                lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                                lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                                lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                                lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                                lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                                lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                                lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                                lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                                lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                                lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                                lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                                lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                                lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                                lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                                lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                                lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                                lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                                lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                                                                                                                                                                },
                                         {
                                                                                  mediaTitle: 'Microsoft Build 2017 - Fluent Design System (AKA Project Neon)',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/May-11-2017-09-01-15.mp4',
                                                                                       thumbnail:
                                                                                                                                   'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                     lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                                lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                                lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                                lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                                lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                                lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                                lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                                lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                                lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                                lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                                lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                                lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                                lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                                lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                                lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                                lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                                lyrics_p17 : 'Take you to the top',
                                                                                                                                                                                lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                                lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                                lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                                lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                                lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                                lyrics_p23 : 'Do the best I can',
                                                                                                                                                                                lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                                lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                                lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                                lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                                lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                                lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                                lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                                lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                                lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                                lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                                lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                                lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                                lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                                lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                                lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                                lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                                lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                                lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                                lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                                lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                                lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                                                                                                                                                                },
                                         {
                                                                                  mediaTitle: 'Mort de Marien Ngouabi',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Mort-de-Marien-Ngouabi-1.wmv.webm',
                                                                        thumbnail:
                                                                                                                    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                      lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                 lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                 lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                 lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                 lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                 lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                 lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                 lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                 lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                 lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                 lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                 lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                 lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                 lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                 lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                 lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                 lyrics_p17 : 'Take you to the top',
                                                                                                                                                                 lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                 lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                 lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                 lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                 lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                 lyrics_p23 : 'Do the best I can',
                                                                                                                                                                 lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                 lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                 lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                 lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                 lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                 lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                 lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                 lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                 lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                 lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                 lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                 lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                 lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                 lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                 lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                 lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                 lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                 lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                 lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                 lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                 lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                                                                                                                                                                         },
                                         {
                                                                                  mediaTitle: 'CNS CONGOLAISE - Mort de Marien Ngouabi - témoignage d\'Okoko',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Mort-de-Marien-témoignage-d-Okoko.mp4',
                                                                                   thumbnail:
                                                                                                                               'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                                 lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                            lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                            lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                            lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                            lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                            lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                            lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                            lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                            lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                            lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                            lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                            lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                            lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                            lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                            lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                            lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                            lyrics_p17 : 'Take you to the top',
                                                                                                                                                                            lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                            lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                            lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                            lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                            lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                            lyrics_p23 : 'Do the best I can',
                                                                                                                                                                            lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                            lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                            lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                            lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                            lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                            lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                            lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                            lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                            lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                            lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                            lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                            lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                            lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                            lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                            lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                            lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                            lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                            lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                            lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                            lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                            lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                            },
                                         {
                                                                                  mediaTitle: 'CNS CONGOLAISE - Mort de Marien Ngouabi - témoignage d\'Okoko 2',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Mort-de-Marien-témoignage-d-Okoko2.webm',
                                                                        thumbnail:
                                                                                                                    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                      lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                 lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                 lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                 lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                 lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                 lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                 lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                 lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                 lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                 lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                 lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                 lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                 lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                 lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                 lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                 lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                 lyrics_p17 : 'Take you to the top',
                                                                                                                                                                 lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                 lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                 lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                 lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                 lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                 lyrics_p23 : 'Do the best I can',
                                                                                                                                                                 lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                 lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                 lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                 lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                 lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                 lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                 lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                 lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                 lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                 lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                 lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                 lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                 lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                 lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                 lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                 lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                 lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                 lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                 lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                 lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                 lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                        },
                                         {
                                                                                  mediaTitle: 'CNS CONGOLAISE - Mort de Marien Ngouabi - témoignage d\'Okoko 3',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Mort-de-Marien-témoignage-d-Okoko3.webm',
                                                                            thumbnail:
                                                                                                                        'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                                                                                                          lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                     lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                     lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                     lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                     lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                     lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                     lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                     lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                     lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                     lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                     lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                     lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                     lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                     lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                     lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                     lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                     lyrics_p17 : 'Take you to the top',
                                                                                                                                                                     lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                     lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                     lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                     lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                     lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                     lyrics_p23 : 'Do the best I can',
                                                                                                                                                                     lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                     lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                     lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                     lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                     lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                     lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                     lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                     lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                     lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                     lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                     lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                                                                                                     lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                     lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                     lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                     lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                     lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                     lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                     lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                     lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                     lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                     lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                                                                                                                                                                     },
                                         {
                                                                                  mediaTitle: 'Yomby et Okoko confirment la responsabilité de Sassou dans l\'assassinat de NGouabi et du Cardinal',
                                                                                  mediaUrl:
                                                                                    '/medias/assets/videos/Yomby-et-Okoko-confirment-la-responsabilité-de-Sassou-dans-l-assassinat-de-NGouabi-et-du-Cardinal.mp4',
                                                                                 thumbnail:
                                                                                                                             'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                               lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                          lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                          lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                          lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                          lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                          lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                          lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                          lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                          lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                          lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                          lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                          lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                          lyrics_p13 : 'And let us stick together and make it happen',
                                                                                          lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                          lyrics_p15 : 'I won\'t think it over again',
                                                                                          lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                          lyrics_p17 : 'Take you to the top',
                                                                                          lyrics_p18 : 'Te prendre au sommet',
                                                                                          lyrics_p19 : 'Tell me every hidden thought',
                                                                                          lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                          lyrics_p21 : 'Come on let me love you again',
                                                                                          lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                          lyrics_p23 : 'Do the best I can',
                                                                                          lyrics_p24 : 'Faire du mieux que je peux',
                                                                                          lyrics_p25 : 'Make me tremble with your breath',
                                                                                          lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                          lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                          lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                          lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                          lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                          lyrics_p31 : 'Ooh watch me my friend',
                                                                                          lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                          lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                          lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                          lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                          lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                          lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                          lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                          lyrics_p39 : 'But everything it seems to be the same',
                                                                                          lyrics_p40 : 'Mais tout semble être pareil',
                                                                                          lyrics_p41 : 'I want you where you are now',
                                                                                          lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                          lyrics_p43 : 'Don\'t wanna lose you',
                                                                                          lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                                                                                                                                                                                                                                              },
                                        {
                                          mediaTitle: 'THOMAS SANKARA - SACRIFICE POUR UNE REVOLUTION',
                                          mediaUrl:
                                            '/medias/assets/videos/ThomasSankara-1984.mp4',

                                  thumbnail:
                                            'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
                                              lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                         lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                         lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                         lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                         lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                         lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                         lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                         lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                         lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                         lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                         lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                         lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                         lyrics_p13 : 'And let us stick together and make it happen',
                                                                                         lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                         lyrics_p15 : 'I won\'t think it over again',
                                                                                         lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                         lyrics_p17 : 'Take you to the top',
                                                                                         lyrics_p18 : 'Te prendre au sommet',
                                                                                         lyrics_p19 : 'Tell me every hidden thought',
                                                                                         lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                         lyrics_p21 : 'Come on let me love you again',
                                                                                         lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                         lyrics_p23 : 'Do the best I can',
                                                                                         lyrics_p24 : 'Faire du mieux que je peux',
                                                                                         lyrics_p25 : 'Make me tremble with your breath',
                                                                                         lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                         lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                         lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                         lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                         lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                         lyrics_p31 : 'Ooh watch me my friend',
                                                                                         lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                         lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                         lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                         lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                         lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                         lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                         lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                         lyrics_p39 : 'But everything it seems to be the same',
                                                                                         lyrics_p40 : 'Mais tout semble être pareil',
                                                                                         lyrics_p41 : 'I want you where you are now',
                                                                                         lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                         lyrics_p43 : 'Don\'t wanna lose you',
                                                                                         lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                        },
                                        {
                                          mediaTitle: 'Gilbert Mundela on DRC Elections Straight Talk Africa',
                                          mediaUrl:
                                            '/medias/assets/videos/rdc/politique/GilbertMundela-on-DRC-Elections-Straight-Talk-Africa.mp4',
                                          thumbnail:
                                            '/medias/assets/videos/250px-Michael_Jordan.JPG',
                                              lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                         lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                         lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                         lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                         lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                         lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                         lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                         lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                         lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                         lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                         lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                         lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                         lyrics_p13 : 'And let us stick together and make it happen',
                                                                                         lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                         lyrics_p15 : 'I won\'t think it over again',
                                                                                         lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                         lyrics_p17 : 'Take you to the top',
                                                                                         lyrics_p18 : 'Te prendre au sommet',
                                                                                         lyrics_p19 : 'Tell me every hidden thought',
                                                                                         lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                         lyrics_p21 : 'Come on let me love you again',
                                                                                         lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                         lyrics_p23 : 'Do the best I can',
                                                                                         lyrics_p24 : 'Faire du mieux que je peux',
                                                                                         lyrics_p25 : 'Make me tremble with your breath',
                                                                                         lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                         lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                         lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                         lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                         lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                         lyrics_p31 : 'Ooh watch me my friend',
                                                                                         lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                         lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                         lyrics_p34 : 'Je te sens atteindre dans mon esprit',
                                                                                         lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                         lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                         lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                         lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                         lyrics_p39 : 'But everything it seems to be the same',
                                                                                         lyrics_p40 : 'Mais tout semble être pareil',
                                                                                         lyrics_p41 : 'I want you where you are now',
                                                                                         lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                         lyrics_p43 : 'Don\'t wanna lose you',
                                                                                         lyrics_p44 : 'Je ne veux pas te perdre',
                                            show_lyrics : 'false',
                                                                                                                                                                              tracks : [
                                                                                                                                                                                  {kind : 'captions',
                                                                                                                                                                                   label : 'English',
                                                                                                                                                                                    srclang : 'en',
                                                                                                                                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                     mode : 'showing'},
                                                                                                                                                                                  {
                                                                                                                                                                                  kind : 'sous-titres',
                                                                                                                                                                               label : 'French',
                                                                                                                                                                                srclang : 'fr',
                                                                                                                                                                                 src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                  }
                                                                                                                                                                              ]

                                        },
                                      ];
                          this.currentVideo = this.playlist[0];

                        }

      }



       /**
       * Load les playlists de l'utilisateur connecté
       */

      loadPlayLists(){

        //On ne load la playlist que si on est connecté

        this.userMediasService.getUserPlaylists().subscribe(x =>

               {this.currentPlayLists = x;
              //alert("userMediasService getUserPlaylists ="+x);
              console.log("userMediasService getUserPlaylists ="+x);
               this.loadPlayListsByIndex(0);
               }
               );


    }

    loadDomaines(){

            //On ne load la playlist que si on est connecté

            this.formationService.getAllDomaines().subscribe(x =>

                   {this.currentDomaines = x;
                 // alert("formationService getDomaines ="+x);
                  console.log("formationService getDomaines ="+x);
                   }
                   );


        }

      loadNextPlayLists(){
            //alert('loadNextPlayLists');
            //alert('this.currentPlayLists='+this.currentPlayLists);
            if(this.currentPlayLists){
               //alert("index play :"+this.currentPlayListsIndex);
                //alert("currentPlayLists length :"+this.currentPlayLists.length);
                 if(this.currentPlayListsIndex<=this.currentPlayLists.length){
                    this.currentPlayListsIndex++;
                 } else{
                  this.currentPlayListsIndex=0;
                 }
                this.loadPlayListsByIndex(this.currentPlayListsIndex);

                }else{
                //alert('this.currentPlayLists is undefined ==>loadNextPlayLists');
                    this.currentPlayListsIndex=0;
                    //TODO A DECOMMENTER
                    //this.loadPlayLists();
                }

      }


      loadPreviousPlayLists(){
                       if(this.currentPlayListsIndex!=0){
                          this.currentPlayListsIndex--;
                       } else{
                        this.currentPlayListsIndex=0;
                       }
                      this.loadPlayListsByIndex(this.currentPlayListsIndex);
      }


      loadPlayListsByIndex(index){
      console.log('loadPlayListsByIndex('+index+')');
           if(this.currentPlayLists!=null&&this.currentPlayLists.length!=0){
                     this.playListModel = this.currentPlayLists[index];
                                                console.log('this.playListModel=' + this.playListModel);
                                                console.log('this.playListModel.medias=' + this.playListModel.medias);
                                                console.log('this.playListModel.medias.length=' + this.playListModel.medias.length);
                                                 let arr = [];
                                                 if(this.playListModel!=null&&this.playListModel.medias!=null&&this.playListModel.medias.length!=0){
                                                 console.log('this.playListModel.medias.length=' + this.playListModel.medias.length);

                                                                    console.log('this.playListModel.medias size==>' + this.playListModel.medias.length);

                                                                                for (let mediaModel of this.playListModel.medias) {
                                                                                console.log("MBC---------------------------------------------------------------");
                                                                                    console.log("Begin copy mediaModel");
                                                                                    var media = {
                                                                                        mediaTitle : mediaModel.mediaTitle,
                                                                                        thumbnail : "/medias/assets/videos/0620638972/denzel-washington_jpg_200x380_q95.JPG",
                                                                                        show_lyrics : "false",

                                                                                        mediaUrl : mediaModel.mediaUrl,
                                                                                        mediaDate : mediaModel.mediaDate,
                                                                                        position : mediaModel.position

                                                                                    };
                                                                                    arr.push(media);
                                                                                        console.log(mediaModel.mediaTitle);
                                                                                         console.log(mediaModel.mediaUrl);
                                                                                        console.log(mediaModel.mediaDate);
                                                                                        console.log(mediaModel.position);
                                                                                     console.log("End copy mediaModel");
                                                                                     console.log("MEC---------------------------------------------------------------");
                                                                                }
                                                                                console.log(arr);

                                                                                    this.playlist = arr;
                                                                                     this.currentVideo = this.playlist[0];
                                                 }
               }
      }


      onVideoChange(video) {
                        this.currentVideo = video;

                      }





          signup() {
              const dialogConfig = new MatDialogConfig();
              this.matDialog.open(SignupComponent, dialogConfig);
            }

            signin() {
                          const dialogConfig = new MatDialogConfig();
                          this.matDialog.open(RegistrationFormComponent, dialogConfig);
                        }



}