import { Routes, RouterModule } from '@angular/router';
import { NgModule} from '@angular/core';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { SportsComponent } from './sports/sports.component';
import { TendancesComponent } from './tendances/tendances.component';
import { EntertainmentComponent } from './entertainment/entertainment.component';
import { RealEstateComponent } from './realestate/realestate.component';
import { SanteComponent } from './sante/sante.component';
import { OpinionComponent } from './opinion/opinion.component';
import { JobsComponent } from './jobs/jobs.component';
import { ItnewsComponent } from './itnews/itnews.component';
import { ImmoComponent } from './immo/immo.component';
import { EvryComponent } from './evry/evry.component';
import { CarsComponent } from './cars/cars.component';
import { CarouselComponent } from './carousel/carousel.component';
import { BusinessComponent } from './business/business.component';
import { AnnoncesComponent } from './annonces/annonces.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HeaderFormationComponent } from './header-formation/header-formation.component';
import { ProductComponent } from './product/product.component';
import { SuccessComponent } from './success/success.component';
import { FailureComponent } from './failure/failure.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/auth.guards';

const appRoutes: Routes = [

    //'rubriques', component: RubriqueComponent },
    //{ path: 'sousrubriques/:rubriqueId', component: SousRubriqueComponent },

    {
            path: 'home',
            component: HomeComponent
            ,
            canActivate: [AuthGuard]
        },
        {
            path: 'sante',
            component: SanteComponent
        },

          {
                        path: 'register',
                        component: RegistrationFormComponent
                    },


          {
                  path: 'logout',
                  component: LoginComponent
              },
    {
            path: 'sports',
            component: SportsComponent
        },
        {
                path: 'tendances',
                component: TendancesComponent
            },
            {
                            path: 'realestate',
                            component: RealEstateComponent
                        },
                        {
                                                    path: 'entertainment',
                                                    component: EntertainmentComponent
                                                },
            {
                    path: 'sante',
                    component: SanteComponent
                },
                {
                        path: 'opinion',
                        component: OpinionComponent
                    },
                    {
                            path: 'jobs',
                            component: JobsComponent
                        },
                        {
                                path: 'itnews',
                                component: ItnewsComponent
                            },
                            {
                                    path: 'immo',
                                    component: ImmoComponent
                                },
                                {
                                        path: 'evry',
                                        component: EvryComponent
                                    },
                                    {
                                            path: 'cars',
                                            component: CarsComponent
                                        },
                                        {
                                                path: 'carousel',
                                                component: CarouselComponent
                                            },
                                            {
                                                    path: 'business',
                                                    component: BusinessComponent
                                                },
                                                {
                                                            path: 'finance',
                                                            component: SanteComponent
                                                        },
                                                {
                                                                                                    path: 'annonces',
                                                                                                    component: AnnoncesComponent
                                                                                                },
{
                        path: 'subjectDisplay?:sujId',
                        component: AppComponent

                    },
                    {
                    path: 'domDisplay?:domaineId',
                                            component: AppComponent

                                        },
                    {
                                            path: 'rubDisplay?:rubId',
                                            component: AppComponent

                                        },

{
    path: 'tb',
    redirectTo: 'tableau-de-bord',
    pathMatch: 'full'
  },
{
        path: '',
        component: HomeComponent
        /*,
        canActivate: [AuthGuard]*/
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' },

    { path: 'checkout', component: ProductComponent },
    { path: 'success', component: SuccessComponent },
    { path: 'failure', component: FailureComponent }


];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,
                               { enableTracing: true } // <-- debugging purposes only
                               )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
