import { Component, OnInit,  Input, Output, ElementRef, EventEmitter} from '@angular/core';
import { User } from '../../_models/user';
import { PlayList } from '../../_models/playlist';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlayListModel } from '../../_models/playlist-model';
import { MediaModel } from '../../_models/media-model';
import { UserMediasService } from '../../_services/user-medias.service';

@Component({
  selector: 'mediastore-edit-play-list',
  templateUrl: './edit-play-list.component.html',
  styleUrls: ['./edit-play-list.component.scss']
})
export class EditPlayListComponent implements OnInit {

 @Output() videoClicked = new EventEmitter<Object>();

editPlayListForm: FormGroup;
currentPlayList: PlayListModel;
playListModel:PlayListModel;
@Input() playListId: string;
currentVideo:{};
playlist:Array<{}>;
newUploadedFiles : any;
@Input() deletedFiles:any;
loading = false;
submitted = false;
@Input() editMode:string;

  constructor(private formBuilder: FormBuilder,
                      private userMediasService: UserMediasService) { }

  ngOnInit(): void {
  this.playListModel = new PlayListModel();
  this.editPlayListForm = this.formBuilder.group({
                        playListTitle: ['', Validators.required],
                        playListFolder: ['', Validators.required],
                         playListOrdre: ['', Validators.required],
                         playListIconUrl: ['', Validators.required],
                         	playListDateDebut: ['', Validators.required],
                         	playListDateFin: ['', Validators.required],
                         	position: ['', Validators.required]

                    });
                    //alert('editMode=' +this.editMode);
                    //alert('playListId=' +this.playListId);
  //if(this.editMode=='true'){
    this.loadPlayLists(this.playListId);
  //}


  }
   // convenience getter for easy access to form fields
   get f() { return this.editPlayListForm.controls; }

  loadPlayLists(id){
        //alert('Loading playlist '+id);
         this.userMediasService.getUserPlaylistById(id).subscribe(x =>

         {this.currentPlayList = x;
        //alert("x="+x);
         this.loadPlayListModel();
         }
         );

        }

onUploadFileListChange(files){
this.newUploadedFiles =  files;
}

        loadPlayListModel(){
                   //alert("this.currentPlayList="+this.currentPlayList);
                   if(this.currentPlayList!=null){
                             this.playListModel = this.currentPlayList;
                                                        // alert(this.playListModel);
                                                         let arr = [];
                                                         if(this.playListModel&&this.playListModel.medias&&this.playListModel.medias.length!=0){
                                                               /* Object.keys(this.playListModel.medias).map(function(key){
                                                                                                 arr.push({[key]:this.playListModel.medias[key]})
                                                                                                 return arr;
                                                                                             });
                                                                                             console.log('Object=',this.playListModel.medias)
                                                                                             console.log('Array=',arr)
                                                                                             this.playlist = arr;*/



                                                                                        for (let mediaModel of this.playListModel.medias) {
                                                                                        console.log("MBC---------------------------------------------------------------");
                                                                                            console.log("Begin copy mediaModel");
                                                                                            var media = {
                                                                                                 entityId : mediaModel.entityId,
                                                                                                mediaTitle : mediaModel.mediaTitle,
                                                                                                thumbnail : "/medias/assets/videos/0620638972/denzel-washington_jpg_200x380_q95.JPG",
                                                                                                show_lyrics : "false",

                                                                                                mediaUrl : mediaModel.mediaUrl,
                                                                                                mediaDate : mediaModel.mediaDate,
                                                                                                position : mediaModel.position

                                                                                            };
                                                                                            arr.push(media);
                                                                                                console.log(mediaModel.mediaTitle);
                                                                                                 console.log(mediaModel.mediaUrl);
                                                                                                console.log(mediaModel.mediaDate);
                                                                                                console.log(mediaModel.position);
                                                                                             console.log("End copy mediaModel");
                                                                                             console.log("MEC---------------------------------------------------------------");
                                                                                        }
                                                                                         this.playlist = arr;
                                                                                         this.currentVideo = this.playlist[0];

                                                         }
                       }
              }
 onVideoChange(video) {
 this.videoClicked.emit(video);
                        this.currentVideo = video;

                      }



              onSubmit() {
                            this.submitted = true;

                            // stop here if form is invalid
                            if (this.editPlayListForm.invalid) {
                                return;
                            }

                            this.loading = true;
                            if(this.f){
                                 this.userMediasService.updateUserPlaylist(this.playListId,this.f.playListTitle.value, this.f.playListFolder.value, this.f.playListOrdre.value, this.newUploadedFiles, this.deletedFiles);
                                 //updateUserPlayListByEntityId(user,playListId, playListTitle, playListFolder, playListOrdre, files,detetedMediasIds);
                            }

                        }


}
