import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { ArticleModel } from '../_models/article.model';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class ArticleService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

         getArticleById(artId) : Observable<ArticleModel> {

            return this.http.get<ArticleModel>(`${environment.apiUrl}/api/articles/`+artId).pipe(
              map((data) => {
                if (data) {

                 console.log("ArtB---------------------------------------------------------------");

                 console.log("Begin ArticleModel");
                  
                  console.log("ArtE---------------------------------------------------------------");


                }
                return data;
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }

            findArticlesBySubject(subjectId) : Observable<ArticleModel[]> {

                        return this.http.get<ArticleModel[]>(`${environment.apiUrl}/api/sujArts/`+subjectId).pipe(
                          map((data) => {
                            if (data) {

                             console.log("ArtB---------------------------------------------------------------");

                             console.log("Begin ArticleModel");

                              console.log("ArtE---------------------------------------------------------------");


                            }
                            return this.convertArticlesModelToArray(data);
                          }),
                          catchError((error) => this.toastService.errorHandlerWithToast(error)),
                        );
                      }

findArticlesBySrub(srubId) : Observable<ArticleModel[]> {

                        return this.http.get<ArticleModel[]>(`${environment.apiUrl}/api/srubArts/`+srubId).pipe(
                          map((data) => {
                            if (data) {

                             console.log("findArticlesBySrub - B---------------------------------------------------------------");

                             console.log("Begin ArticleModel");

                              console.log("findArticlesBySrub - E---------------------------------------------------------------");


                            }
                            return this.convertArticlesModelToArray(data);
                          }),
                          catchError((error) => this.toastService.errorHandlerWithToast(error)),
                        );
                      }



                      convertArticlesModelToArray(articleModels){
                                        console.log("articleModels.length="+articleModels.length);
                                        if(articleModels&&articleModels.length!=0){

                                           let arrArtPhotos = [];

                                            let arrArticle = [];
                                           for (let article of articleModels) {
                                               for (let photo of article.photos) {
                                                            console.log("RBC---------------------------------------------------------------");
                                                                        console.log("Begin copy ArticlePhotoModel");
                                                                        var photoModel = {
                                                                               entityId:photo.entityId,
                                                                                   artPhotoTitle:photo.artPhotoTitle,
                                                                                   artPhotoUrl:photo.artPhotoUrl,
                                                                                   artPhotoDate:photo.artPhotoDate

                                                                        };
                                                                        arrArtPhotos.push(photoModel);
                                                        }
                                               console.log("arrArtPhotos="+arrArtPhotos);
                                           let arrArtVideos = [];
                                        for (let video of article.videos) {
                                                        console.log("RBC---------------------------------------------------------------");
                                                                    console.log("Begin copy ArticleVideoModel");
                                                                    var videoModel = {
                                                                          entityId:video.entityId,
                                                                           artVideoTitle:video.artPhotoTitle,
                                                                           artVideoUrl:video.artPhotoUrl,
                                                                           artVideoDate:video.artPhotoDate

                                                                    };
                                                                    arrArtVideos.push(videoModel);
                                                    }
                                                    console.log("arrArtVideos="+arrArtVideos);
                                           console.log("ARTBC---------------------------------------------------------------");
                                               console.log("Begin copy ArticleModel");
                                               var pays:string="";
                                               var articleModel = {
                                                   entityId:article.entityId,
                                                   	artTitle:article.artTitle,
                                                   	artResume:article.artResume,
                                                   	artIntro:article.artIntro,
                                                   	artHtmlText:article.artHtmlText,
                                                   	artExtUrl:article.artExtUrl,
                                                   	artSourceSite:article.artSourceSite,
                                                   	topHeadLine:article.topHeadLine,
                                                   	panel:article.panel,
                                                   	ordre:article.ordre,
                                                   	dateCrea:article.dateCrea,
                                                   	dateMaj:article.dateMaj,
                                                   	photos : arrArtPhotos,
                                                   	videos : arrArtVideos,
                                                   	pays : article.paysModel?pays.concat(article.paysModel.paysCode, '-',article.paysModel.paysLib):''
                                               };

                                               arrArticle.push(articleModel);
                                                console.log("End copy ArticleModel");
                                                console.log("ARTEC---------------------------------------------------------------");
                                           }
                                           console.log(arrArticle);
                                           return arrArticle;

                                        }

                                       }


}