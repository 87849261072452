import { Component, Renderer2,inject, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import {FormationService} from '../_services/formation.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {DisplayType} from "@/shared/rail-road/displayType";

@Component({
  selector: 'mediastore-session-sem-form',
  templateUrl: './session-form.sem.component.html',
  styleUrls: ['./session-form.sem.component.scss']
})
export class SessionFormSemComponent implements OnInit {
  public sessionId!: string;
  sessionsSemFormation: Array<{}>;
  sessionsSemCours: Array<{}>;
  sessionsSemSlides: Array<{}>;
  sessionsSemVideos: Array<{}>;
   sessionsSemQuizz: Array<{}>;

   events: string[] = [];
    showPlayLists: boolean;
    showSlides: boolean;
    showCours: boolean;
    showQuizz: boolean;

    listDisplayTypes: Array<DisplayType> = [
            new DisplayType("mT0",0, 'PlayLists'),
            new DisplayType("mT1",1, 'Slides'),
            new DisplayType("mT2",2, 'Cours'),
            new DisplayType("mT3",3, 'Quizz')
        ];


  constructor(private formationsService:FormationService, private renderer: Renderer2,private formBuilder: FormBuilder,private router: Router,private activatedRoute:ActivatedRoute) {

  }



  ngOnInit() {

  this.showPlayLists=true;


    this.sessionId = this.activatedRoute.snapshot.paramMap.get('sessionId') as string;
   // alert("this.annonceId="+this.annonceId);
   if (this.sessionId){
                      this.sessionDisplay(this.sessionId);
                /*       this.route.paramMap.pipe(
                           switchMap((params: ParamMap) =>
                             this.sessionDisplay(params.get('id')!));
                         );*/


       }



  }

  getFirsSem(){
    var sessFormModel = this.sessionsSemFormation[0];
    return sessFormModel;
  }





  sessionDisplay(sessionId){
    console.log('HEAD SELECTED SESSION  - sessionDisplay?sessionId='+sessionId);
     this.formationsService.findBySessionFormationModelSessFormIdOrderByOrdreAsc(sessionId).subscribe(x => this.sessionsSemFormation = x);
    }

    topicsDisplay(topicId){
        console.log('HEAD SELECTED SESSION  - topicsDisplay?topicId='+topicId);
        if(topicId==0){
            this.showPlayLists=true;
            this.showSlides=false;
            this.showCours=false;
            this.showQuizz=false;
        }else if(topicId==1){
             this.showSlides=true;
             this.showPlayLists=false;
             this.showCours=false;
             this.showQuizz=false;
        }else if(topicId==2){
              this.showCours=true;
              this.showSlides=false;
              this.showPlayLists=false;
              this.showQuizz=false;
        }else if(topicId==3){
        this.showQuizz=true;
           this.showCours=false;
           this.showSlides=false;
           this.showPlayLists=false;
        }
         //this.formationsService.findBySessionFormationModelSessFormIdOrderByOrdreAsc(sessionId).subscribe(x => this.sessionsSemFormation = x);
        }


}
