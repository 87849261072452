import { Component, OnInit,Input,ViewChild, Output, EventEmitter   } from '@angular/core';
import {RubriquesService} from '../_services/rubrique.service'
import {SRubriquesService} from '../_services/srubrique.service';
import {SubjectService} from '../_services/subject.service';
import {ArticleService} from '../_services/article.service';
import {RubriqueModel} from '../_models/rubrique.model';
import {SRubriqueModel} from '../_models/srubrique.model';
import {SubjectGroupesModel} from '../_models/subject-groupes.model'
import {SubjectModel} from '../_models/subject.model';
import {ArticleModel} from '../_models/article.model';
import {SubjectGroupesService} from '../_services/subject-groupes.service';
import {ActivatedRoute} from '@angular/router';
declare const activSmenu: any;
declare const desactSmenu: any;
declare const _gaq: any;

@Component({
  selector: 'mediastore-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
    constructor(private rubriquesService:RubriquesService,private sRubriquesService:SRubriquesService,private subjectService:SubjectService,private articleService:ArticleService,private subjectGroupesService:SubjectGroupesService, private route:ActivatedRoute) {
        this.loadSubjectGrps();
    }
        @ViewChild('menuHtml') menuHtml: any;
         subjectGroupes:Array<{}>;
         parentSRubriques: Array<{}>;
         ssRubriques: Array<{}>;
         @Output() articles:EventEmitter<Array<{}>> = new EventEmitter<Array<{}>>();
         currentArticles:Array<{}>;
         subjId:string;
         rubId:string;

         /*static params = {
            subjectId:'sujId',
            rubriqueId:'rubId'

         };*/


          ngOnInit() {

            //this.subjId=this.route.snapshot.paramMap.get(HeaderMenuComponent.params.subjectId);
            //this.rubId=this.route.snapshot.paramMap.get(HeaderMenuComponent.params.rubriqueId);

             console.log(location.search)
             // |> '?q=Angular+NgRx'
             const params = new URLSearchParams(location.search);

             this.rubId = params.get('rubId');
             this.subjId = params.get('sujId');

            console.log('rubId='+this.rubId);

            this.loadSubjectGrps();

            if(this.subjId){
                this.subjectDisplay(this.subjId);
                console.log('this.subjectDisplay('+this.subjId+')');
            }else if(this.rubId){
                this.rubDisplay(this.rubId);
                console.log('this.rubDisplay('+this.rubId+')');
            }

          }

          activMenu(index){
            activSmenu(index);
          }

           desactivMenu(index){
                      desactSmenu(index);
                    }

           googoo(desc){
           _gaq.push(['_trackEvent', 'Menu', 'Click', desc]);
           }

loadSubjectGrps(){
             this.subjectGroupesService.getAllSubjectGroupes().subscribe(x =>
             {this.subjectGroupes = x;
                this.menuHtml.nativeElement.innerHTML=this.getMenuHtml();
             }

             );


            }


 subjectDisplay (sujId){
  console.log('HEAD COMP  - subjectDisplay?sujId='+sujId);
    this.sRubriquesService.findSrubriquesOfFirstRubriqueOfSubject(sujId).subscribe(x =>
    {
    this.parentSRubriques = x;

     //this.parentSRubriques=this.convertSRubriquesModelToArray(this.currentParentSRubriques);
        //Récupération des articles de la prem srub de la prem rub du sujet
        this.articleService.findArticlesBySubject(sujId).subscribe(y =>

         {this.currentArticles = y;
             console.log("this.currentArticles="+this.currentArticles);
                         this.articles.emit(this.currentArticles);
             }
         );
        }

    );

  }

  getMenuHtml(){
  var htmlMenu = this.subjectGroupesService.convertSubjectGroupesModelToHtmlList(this.subjectGroupes);

   return htmlMenu;
  }


  //Articles d'une sous-rubrique
  artDisplay(srubId){
  console.log('HEAD COMP  - artDisplay?srubId='+srubId);
    //Articles de la premiere sous - rubrique de la première rubrique du sujet
        this.articleService.findArticlesBySrub(srubId).subscribe(x =>

        {this.currentArticles = x;
        this.articles.emit(this.currentArticles);
        }


        );

    }



  rubDisplay(rubId){
  console.log('HEAD COMP  - rubDisplay?rubId='+rubId);
   this.sRubriquesService.getSRubriqueByRubId(rubId).subscribe(x => this.parentSRubriques = x);
   //this.parentSRubriques = this.convertSRubriquesModelToArray(this.currentParentSRubriques);
  }

  srubDisplay(srubId){
   console.log('HEAD COMP  - srubDisplay?srubId='+srubId);
    this.sRubriquesService.getSRubriqueBySRubId(srubId).subscribe(x => this.ssRubriques = x);
    //this.ssRubriques = this.convertSRubriquesModelToArray(this.ssRubriques);
  }








  }

