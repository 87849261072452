import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { catchError } from 'rxjs/operators';
import {MatDialog, MatDialogRef,MatDialogConfig} from '@angular/material/dialog';
import {SignupComponent} from '../signup/signup.component';
import {RegistrationFormComponent} from '../registration-form/registration-form.component';
import { AuthenticationService } from '../_services/authentication.service';


@Component({
  selector: 'mediastore-topper',
  templateUrl: './topper.component.html',
  styleUrls: ['./topper.component.scss']
})
export class TopperComponent implements OnInit {

currentUser: User;
searchForm: FormGroup;
isLoggedIn:boolean;

  constructor(private formBuilder: FormBuilder, private userService: UserService,private authenticationService: AuthenticationService, private matDialog: MatDialog) { }

  ngOnInit(): void {
   this.searchForm = this.formBuilder.group({
                              searchQ: ['', Validators.required]

                          });
                   //this.currentUser = this.getCurrentUser();
                  //this.isLoggedIn=this.currentUser!=null;
                  this.isLoggedIn=this.getCurrentUser();

  }

  ngAfterViewInit(){
  this.isLoggedIn = this.getCurrentUser();
  }

  getCurrentUser(){
                      var tokenExists:boolean=localStorage&&localStorage.getItem('currentUser')!=null;
                      /*var result:User=null;
                      if(tokenExists){
                        this.userService.getUserByToken().subscribe((x) => {
                                            this.currentUser=x;

                                              return this.currentUser;
                                           },
                                           catchError((error) => {
                                           //this.toastService.errorHandlerWithToast(error);
                                           this.currentUser=null;
                                           console.log(error);
                                           alert(error);
                                           return null;
                                           }
                                            ),

                                        );
                      }*/
                      //this.isLoggedIn=this.currentUser!=null;
                      this.isLoggedIn = tokenExists;
                      //alert("getCurrentUser ==> this.isLoggedIn="+this.isLoggedIn);
                      //return this.currentUser;
                      return this.isLoggedIn ;
                      }

                      signup() {
                                    const dialogConfig = new MatDialogConfig();
                                    const dialogRef = this.matDialog.open(SignupComponent, dialogConfig).afterClosed().subscribe(result => {
                                                                                                          this.isLoggedIn = this.getCurrentUser();
                                                                                                        });
                                  }

                                  signin() {
                                                const dialogConfig = new MatDialogConfig();
                                                const dialogRef = this.matDialog.open(RegistrationFormComponent, dialogConfig);
                                              }

                                              logout() {
                                                      this.authenticationService.logout();
                                                      //this.router.navigate(['/login']);
                                                  }





}
