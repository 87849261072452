import { Component, Renderer2,inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import {FormationService} from '../_services/formation.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SignupComponent} from '../signup/signup.component';
import {RegistrationFormComponent} from '../registration-form/registration-form.component';
import { catchError } from 'rxjs/operators';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'mediastore-session-form',
  templateUrl: './session-form.component.html'
})
export class SessionFormComponent implements OnInit {
  public annonceId!: string;
  sessionsFormation: Array<{}>;
  slides:Array<{}>;
  searchForm: FormGroup;
  currentUser: User;
  isLoggedIn:boolean;
  //private activatedRoute : ActivatedRoute = inject(ActivatedRoute);
  constructor(private formationsService:FormationService, private renderer: Renderer2,private userService: UserService,private authenticationService: AuthenticationService,private formBuilder: FormBuilder,private router: Router,private activatedRoute:ActivatedRoute, private matDialog: MatDialog) {
  //this.currentUser = this.getCurrentUser();
  this.isLoggedIn= this.getCurrentUser();
  }

  ngOnInit() {
  this.searchForm = this.formBuilder.group({
                              searchQ: ['', Validators.required]

                          });
                   //this.currentUser = this.getCurrentUser();
                  //this.isLoggedIn=this.currentUser!=null;

    this.annonceId = this.activatedRoute.snapshot.paramMap.get('id') as string;
   // alert("this.annonceId="+this.annonceId);
   if (this.annonceId){
                      this.sessionDisplay(this.annonceId);
                /*       this.route.paramMap.pipe(
                           switchMap((params: ParamMap) =>
                             this.sessionDisplay(params.get('id')!));
                         );*/


       }

        this.slides = [
                                     { src: 'https://images.radio-canada.ca/q_auto,w_1250/v1/ici-info/16x9/huawei-television-intelligente-harmony-os-george-zhao.jpg'},
                                     { src: 'https://images.frandroid.com/wp-content/uploads/2019/08/honor-vision-4.png' },
                                     { src: 'https://images.frandroid.com/wp-content/uploads/2019/08/honor-vision-2.png' },
                                     { src: 'https://www.larevuedudigital.com/wp-content/uploads/2019/08/Huawei-HarmonyOS-BF2-.jpg' }
                                   ];

  }

   signup() {
                const dialogConfig = new MatDialogConfig();
                this.matDialog.open(SignupComponent, dialogConfig);
              }

              signin() {
                            const dialogConfig = new MatDialogConfig();
                            this.matDialog.open(RegistrationFormComponent, dialogConfig);
                          }

                           logout() {
                                  this.authenticationService.logout();
                                  //this.router.navigate(['/login']);
                              }

                              getCurrentUser(){
                                                    var tokenExists:boolean=localStorage&&localStorage.getItem('currentUser')!=null;
                                                    /*var result:User=null;
                                                    if(tokenExists){
                                                      this.userService.getUserByToken().subscribe((x) => {
                                                                          this.currentUser=x;

                                                                            return this.currentUser;
                                                                         },
                                                                         catchError((error) => {
                                                                         //this.toastService.errorHandlerWithToast(error);
                                                                         this.currentUser=null;
                                                                         console.log(error);
                                                                         alert(error);
                                                                         return null;
                                                                         }
                                                                          ),

                                                                      );
                                                    }*/
                                                    //this.isLoggedIn=this.currentUser!=null;
                                                    this.isLoggedIn=tokenExists;
                                                    //alert("getCurrentUser ==> this.isLoggedIn="+this.isLoggedIn);
                                                    //return this.currentUser;
                                                    return this.isLoggedIn;
                                                    }

  sessionDisplay(annonceId){
    console.log('HEAD COMP  - sessionDisplay?sessionId='+annonceId);
     this.formationsService.findByAnnonceFormationModelAnnFormId(annonceId).subscribe(x => this.sessionsFormation = x);
    }


}
