import './polyfills';
import {enableProdMode} from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {environment} from './environments/environment';

if(environment.production){
    enableProdMode();
    let script = document.createElement('script');
    script.onload = bootstrap;
    script.onerror = bootstrap;
    script.src = '/medias/assets/config/config.js';
    document.head.appendChild(script);
}else{
    bootstrap();
}




function bootstrap(){
platformBrowserDynamic().bootstrapModule(AppModule);
}
