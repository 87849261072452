import { Component, OnInit } from '@angular/core';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material/progress-spinner';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-onglet-videos',
  templateUrl: './onglet-videos.component.html',
  styleUrls: ['./onglet-videos.component.scss']
})
export class OngletVideosComponent implements OnInit {
  public rangEtapeEncours = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
