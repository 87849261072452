import { Component, OnInit, ViewChild, Input, Renderer2  } from '@angular/core';

@Component({
  selector: 'mediastore-mediaplayer',
  templateUrl: './mediaplayer.component.html',
  styleUrls: ['./mediaplayer.component.scss']
})

 export class MediaplayerComponent implements OnInit {

      @ViewChild('videoplayer') videoElement: any;
      @ViewChild("progress1") seeker: any;
      @ViewChild("progress") progress: any;
      @ViewChild('slider') slider: any;
      @Input() currentVideo;
      @Input() videos: Array<{}>;
      private currentIndex:number=0;

       color = 'primary';
       mode = 'determinate';
       value = 0;
       bufferValue = 75;
       videoDuration;
       videoTimeElapsed;
        showLyrics : boolean;

      video: HTMLVideoElement;
      progressBarStyle = {
        flexBasis: '0%',
      };
      iconClass = 'k-i-play';
      mouseDown = false;

      constructor(private renderer: Renderer2) {

      }

      togglePlay() {
      if(this.videoElement){
      const method = this.videoElement.nativeElement.paused ? 'play' : 'pause';
     //this.video[method]();

        //alert(this.videoElement);
        if(this.videoElement.nativeElement.paused){
            this.videoElement.nativeElement.play();
        }else{
            this.videoElement.nativeElement.pause();
        }

                setTimeout(() => this.updateButton(), 10);
        }



      }

      toggleShowLyrics(){
        this.showLyrics = !this.showLyrics;
        //setTimeout(() => this.videoElement.nativeElement.focus());
      }

      updateButton() {
       //if(this.videoElement){
              //alert(this.videoElement);
              var icon :string;
              if(this.videoElement.nativeElement.paused){
                  icon = 'k-i-play' ;
              }else{
                  icon = 'k-i-pause' ;
              }
                this.iconClass = icon;

              //}



      }


     public nextVideo(){
         if(this.currentIndex!=this.videos.length){
            this.currentIndex++;
         } else{
          this.currentIndex=0;
         }
        this.currentVideo = this.videos[this.currentIndex];

        //this.addTracks();
        this.showLyrics = this.currentVideo.showLyrics;
        setTimeout(() => this.videoElement.nativeElement.focus());
                setTimeout(() => this.videoElement.nativeElement.play());

                this.showLyrics = (this.currentVideo.showLyrics=='true');
      }

      public previousVideo(){
               if(this.currentIndex!=0){
                  this.currentIndex--;
               } else{
                this.currentIndex=0;
               }
              this.currentVideo = this.videos[this.currentIndex];

              //Add tracks
              //this.addTracks();
              setTimeout(() => this.videoElement.nativeElement.focus());
                      setTimeout(() => this.videoElement.nativeElement.play());
                      this.showLyrics = (this.currentVideo.showLyrics=='true');
            }


            addTracks(){
                 let tracks = this.currentVideo.tracks;
                  for (var i in tracks) {
                    let track= "<track kind='" + tracks[i].kind +"' label='" + tracks[i].label +"' srclang='" + tracks[i].srclang +"' src='" + tracks[i].src +"' mode='" + tracks[i].mode +"' default>";
                    //alert(track);
                    if(this.videoElement){
                        this.videoElement.nativeElement.insertAdjacentHTML("beforeend", track);
                    }
                  }
            }

      toFullScreen(e) {


        if (this.videoElement.nativeElement.requestFullscreen) {

          this.videoElement.nativeElement.requestFullscreen(e);
        } else if (this.videoElement.nativeElement.mozRequestFullScreen) {

          this.videoElement.nativeElement.mozRequestFullScreen(e);
        } else if (this.videoElement.nativeElement.webkitRequestFullscreen) {

          this.videoElement.nativeElement.webkitRequestFullscreen(e);
        }

      }

      handleVolumeChange(e) {
        const { target } = e;
        const { value } = target;
       // alert("value="+value);
        this.videoElement.nativeElement.volume = value;
      }

      muteVideo(){
        this.videoElement.nativeElement.volume = 0;
        this.slider.nativeElement.value=0;
      }

      unmuteVideo(){
              this.videoElement.nativeElement.volume = 1;
              this.slider.nativeElement.value=1;
            }



      handleProgress() {

        const percent = (this.videoElement.nativeElement.currentTime / this.videoElement.nativeElement.duration) * 100;
        //alert(percent);
        this.seeker.nativeElement.flexBasis = percent +' %';
        this.value=percent;
        this.videoTimeElapsed = new Date(this.videoElement.nativeElement.currentTime * 1000).toISOString().substr(11, 8);
        this.videoDuration = new Date(this.videoElement.nativeElement.duration * 1000).toISOString().substr(11, 8);
      }

      forward(){
        //if(this.value!=100){
                        if(this.value>90){
                              this.value=100;
                        }else{
                              this.value=this.value+10;
                        }
                         this.seeker.nativeElement.flexBasis = this.value +' %';
                         this.videoElement.nativeElement.currentTime = (this.value*this.videoElement.nativeElement.duration)/100;
                   //}
      }

      rewind(){
           //if(this.value!=0){
                if(this.value>10){
                      this.value=this.value-10;
                }else{
                      this.value=0;
                }
                 this.seeker.nativeElement.flexBasis = this.value +' %';
                this.videoElement.nativeElement.currentTime = (this.value*this.videoElement.nativeElement.duration)/100;
           //}

      }

      seek(e:MouseEvent) {

        const { srcElement: progress, offsetX } = e;
        //alert("this.videoElement.nativeElement.duration="+this.videoElement.nativeElement.duration);
       //alert("offsetX  = " + offsetX + " - this.current = " + this.value/100* this.videoElement.nativeElement.duration);
        //const {  offsetWidth } = progress1;

        //alert('progress.offsetWidth='+progress.nativeElement.offsetWidth);
        //alert('offsetX' +offsetX);
        //alert('this.videoElement.nativeElement.offsetWidth='+this.videoElement.nativeElement.offsetWidth);
        //alert('this.videoElement.nativeElement.duration=' +this.videoElement.nativeElement.duration);
          const seekTime = (offsetX /this.videoElement.nativeElement.offsetWidth)* this.videoElement.nativeElement.duration ;
         // alert('seekTime='+seekTime);
           this.videoElement.nativeElement.currentTime = Math.floor(seekTime);
           //this.value=seekTime;

      }

      ngOnInit() {
        //const { nativeElement } = this.videoElement;
        //this.video = nativeElement;
       //this.video =  this.videoElement.nativeElement
        /* if (this.videoElement) {
            this.renderer.listen(this.videoElement, 'ended', () => console.log('video ended'));
         }*/
             //this.videoElement.addEventListener('ended', () => console.log('video ended'));
        //
        this.addTracks();
        this.showLyrics = this.currentVideo.showLyrics;
        setTimeout(() => this.videoElement.nativeElement.focus());
        setTimeout(() => {
                  if(this.videoElement){
                    this.videoElement.nativeElement.play();
                  }


                  });
        this.togglePlay();
      }

      ngAfterViewInit() {
      console.log('Focusing Here...');
            setTimeout(() => this.videoElement.nativeElement.focus());
            setTimeout(() => this.videoElement.nativeElement.play());
            this.addTracks();
            this.showLyrics = (this.currentVideo.showLyrics=='true');
            console.log('this.showLyrics='+this.showLyrics);
      }

      ngAfterViewChange() {
            console.log('Focusing Here...');
                  setTimeout(() => this.videoElement.nativeElement.focus());
                  setTimeout(() => this.videoElement.nativeElement.play());
                  this.addTracks();
                  this.showLyrics = (this.currentVideo.showLyrics=='true');
                   console.log('this.showLyrics='+this.showLyrics);
            }

      ngOnChanges(changes) {
        //if (this.videoElement) {
          this.progressBarStyle.flexBasis = '0%';
          this.value=0;
          setTimeout(() => {
                    if(this.videoElement){
                      this.videoElement.nativeElement.play();
                    }


                    });

        //}
      }


 }