import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { SecretModel } from '../_models/secret.model';
import { CheckoutUrlModel } from '../_models/checkoutUrl.model';
import {ToastService} from '../_services/toast.service';
import { User } from '../_models/user';


@Injectable({ providedIn: 'root' })
export class StripeService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }



   checkout(priceId:string) : Observable<CheckoutUrlModel> {
   console.log("Begin checkout");
   var formData: FormData = new FormData();
   var user:User=JSON.parse(localStorage.getItem('currentUser'));

                   formData.append('priceId', priceId);

                           const headersObject = new HttpHeaders({
                                                     'Content-Type':  'application/json',
                                                     'Access-Control-Allow-Credentials' : 'true',
                                                     'Access-Control-Allow-Origin': '*',
                                                     'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                                     'Access-Control-Allow-Headers': '*',
                                                     'Authorization': user.id_token
                                                   })
                           //alert('user.id_token=' + user.id_token);


                                 const httpOptions = {
                                   headers: headersObject
                                 };

     return this.http.post<any>(`${environment.apiUrl}/payment/createCheckoutSession`, formData,httpOptions)
     .pipe(map(data => {


                         return data;
                     }));


   }


                                                                                                                                                              
}