import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from './pop-up/pop-up.component';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-onglet-cours',
  templateUrl: './onglet-cours.component.html',
  styleUrls: ['./onglet-cours.component.scss']
})
export class OngletCoursComponent implements OnInit {

  dateEffet!: Date;
  public rangEtapeEncours = 0;


  hasError!: boolean;


  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
   // this.getModeSecoursCourant();
  }

  /*getModeSecoursCourant() {
    this.modeSecoursService.getModeSecoursCourant().subscribe(
      (response) => {
        this.responseModeSecours = response;
        this.hasError = false;
        console.log("Response Mode Secours Courant : ");
        console.log(this.responseModeSecours);
        if (this.responseModeSecours?.dateEffet) {
          this.dateEffet = this.responseModeSecours.dateEffet;
          this.isModeSecours = this.responseModeSecours.modeSecours;
        }
        this.getModeSecoursFutur();
      },
      (error) => {
        console.error(error);
        this.hasError = true;
        this.openErrorSnackBar();
      }
    );
  }
*/




  openErrorSnackBar() {
    this._snackBar.open('Une erreur technique est survenue, veuillez réessayer ultérieurement svp.', 'Fermer', {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'errorSnack'
    });
  }

}
