import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TableauDeBordComponent } from './tableau-de-bord.component';
import {
    OngletVideosComponent
} from "@/tableau-de-bord/onglet-videos/onglet-videos.component";
import {OngletCoursComponent} from "@/tableau-de-bord/onglet-cours/onglet-cours.component";
import {OngletSlidesComponent} from "@/tableau-de-bord/onglet-slides/onglet-slides.component";
import {
    OngletQuizzComponent
} from "@/tableau-de-bord/onglet-quizz/onglet-quizz.component";

const routes: Routes = [
    {path: 'tableau-de-bord', component: TableauDeBordComponent,

    children: [
        {
            path: 'onglet-cours',
            component: OngletCoursComponent
        },
        {
            path: 'onglet-slides',
            component: OngletSlidesComponent
        },
        {
            path: 'onglet-videos',
            component: OngletVideosComponent
        },
        {
            path: 'onglet-quizz',
            component: OngletQuizzComponent
        }
        ]
    }
    ];



@NgModule({
    imports: [RouterModule.forRoot(routes,{enableTracing: true})],
    exports: [RouterModule]
})
export class TableauDeBordRoutingModule { }
