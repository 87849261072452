import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { SubjectGroupesModel } from '../_models/subject-groupes.model';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class SubjectGroupesService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

   /* getUser() : Observable<any>{

    var user:User=JSON.parse(localStorage.getItem('currentUser'));


        const headersObject = new HttpHeaders({
                                  'Content-Type':  'application/json',
                                  'Access-Control-Allow-Credentials' : 'true',
                                  'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                  'Access-Control-Allow-Headers': '*',
                                  'Authorization': user.id_token
                                })
        //alert('user.id_token=' + user.id_token);


              const httpOptions = {
                headers: headersObject
              };
            return this.http.get<any>(`${environment.apiUrl}/auth-api/account`,httpOptions);

        }*/



         getAllSubjectGroupes() : Observable<SubjectGroupesModel[]> {

            return this.http.get<SubjectGroupesModel[]>(`${environment.apiUrl}/api/subjGrps`).pipe(
              map((data) => {
               // if (data) {
                //this.convertSubjectGroupesModelToArray(data);
               // }
                return this.convertSubjectGroupesModelToArray(data);
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }

             convertSubjectGroupesModelToArray(subjGrps){

          console.log("Begin convertSubjectGroupesModelToArray---------------------------------------------------------------");
          console.log("Nombre de SubjectGroupes ==>"+subjGrps.length);
               if(subjGrps&&subjGrps.length!=0){


                   let arrSubjectGr = [];
                  for (let subjectGr of subjGrps) {

                              let arrSuj = [];
                                          for (let subject of subjectGr.subjects) {
                                                  let arrRub = [];
                                                                                   for (let rubrique of subject.rubriques) {
                                                                                                   console.log("RBC---------------------------------------------------------------");
                                                                                                               console.log("Begin copy RubriqueModel");
                                                                                                               var rubriqueModel = {
                                                                                                                   	entityId:rubrique.entityId,
                                                                                                                   	rubriqueCode:rubrique.rubriqueCode,
                                                                                                                   	rubriqueText:rubrique.rubriqueText,
                                                                                                                   	rubriqueDesc:rubrique.rubriqueDesc,


                                                                                                               };
                                                                                                               arrRub.push(rubriqueModel);
                                                                                               }
                                                      console.log("SBC---------------------------------------------------------------");
                                                                  console.log("Begin copy SubjectModel");
                                                                  var subj = {
                                                                      entityId:subject.entityId,
                                                                      	subjectCode:subject.subjectCode,
                                                                      	subjectText:subject.subjectText,
                                                                      	subjectDesc:subject.subjectDesc,

                                                                      	rubriques:arrRub

                                                                  };

                                                                  arrSuj.push(subj);
                                                  }
                  console.log("SGBC---------------------------------------------------------------");
                      console.log("Begin copy SubjectGroupesModel");
                      var subjGrp = {
                          entityId:subjectGr.entityId,
                          	subjectGroupeCode:subjectGr.subjectGroupeCode,
                          	subjectGroupeText:subjectGr.subjectGroupeText,
                          	subjectGroupeDesc:subjectGr.subjectGroupeDesc,
                          	subjects : arrSuj

                      };

                       arrSubjectGr.push(subjGrp);
                       console.log("End copy mediaModel");
                       console.log("SGEC---------------------------------------------------------------");
                  }
                  console.log(arrSubjectGr);
                  return arrSubjectGr;

               }
                  console.log("End convertSubjectGroupesModelToArray---------------------------------------------------------------");
              }

 convertSubjectGroupesModelToHtmlList(subjGrps){
          var liList:string="";
          console.log("Begin convertSubjectGroupesModelToliList---------------------------------------------------------------");
          console.log("Nombre de SubjectGroupes ==>"+subjGrps.length);
               if(subjGrps&&subjGrps.length!=0){
              //liList = liList.concat("<div class='mTmenu'><ul>");
              /*liList = liList.concat("<ul>");



                              liList = liList.concat("<li id=\"navLi\"><a href=\"/medias/evry\" name=\"&amp;lid=top-nav:Evry\">Evry</a></li>");
                              liList = liList.concat("<li id=\"navSports\" class=\"lite\"><a href=\"/medias/sports\" name=\"&amp;lid=top-nav:Sports\">Sports</a></li>");
                               liList = liList.concat("<li id=\"navEnt\" ><a href=\"/medias/entertainment\" name=\"&amp;lid=top-nav:Divertissement\">Divertissement</a></li>");
                              liList = liList.concat("<li id=\"navNews\" class=\"lite\"><a href=\"/medias/itnews\" name=\"&amp;lid=top-nav:Digital news\">Digital news</a></li>");
                              liList = liList.concat("<li id=\"navHealth\"><a href=\"/medias/sante\" name=\"&amp;lid=top-nav:Sante\">Santé</a></li>");
                              liList = liList.concat("<li id=\"navBusiness\" class=\"lite\"><a href=\"/medias/business\" name=\"&amp;lid=top-nav:Affaires\">Affaires</a></li>");
                               liList = liList.concat("<li id=\"navFinances\"><a href=\"/medias/finance\" name=\"&amp;lid=top-nav:Finances\">Finances</a></li>");
                               liList = liList.concat("<li id=\"navRealEstate\" class=\"lite\"><a href=\"/medias/immo\" name=\"&amp;lid=top-nav:Immobilier\">Immobilier</a></li>");
                                liList = liList.concat("<li id=\"navJobs\"><a href=\"/medias/jobs\" name=\"&amp;lid=top-nav:Jobs\">Emplois</a></li>");
                                //liList = liList.concat("<li id=\"navLifeStyle\" class=\"lite\"><a href=\"/medias/tendances\" name=\"&amp;lid=top-nav:Tendances\">Tendances</a></li>");
                                //liList = liList.concat("<li id=\"navAnnonces\"><a href=\"/medias/annonces\" name=\"&amp;lid=top-nav:Annonces\">Emplois</a></li>");
                                liList = liList.concat("</ul>");
                                liList = liList.concat("<ul>");*/


var itemIndex=0;
                  for (let subjectGr of subjGrps) {
                  itemIndex++;
                  liList = liList.concat("<li  id='mT",String(itemIndex), "'><a onmouseover=\"activSmenu(", String(itemIndex), ")\" onmouseout=\"desactSmenu(",String(itemIndex),",'mTCgrey')\" onclick='googoo('",subjectGr.subjectGroupeDesc,"')'>",subjectGr.subjectGroupeDesc," </a> ");
                  liList =liList.concat("<div id='mS", String(itemIndex), "' onmouseover=\"activSmenu(", String(itemIndex), ")\" onmouseout=\"desactSmenu(",String(itemIndex),",'mTCgrey')\" style=\"display: none;\">");
                  liList =liList.concat("<div class='mSmenu_gl' style='width:185px'>");
                  liList=liList.concat("<div style='width:500px' >");

                  liList=liList.concat("<ul class=\"mTmenu_ul\">");
                                            var subjectItemIndex=0;
                                          for (let subject of subjectGr.subjects) {
                                            subjectItemIndex++;
                                          liList=liList.concat("<li class='lite'> <a href=\"/medias/subjectDisplay?sujId=",subject.entityId,"\" title='",subject.subjectDesc,"'><div>", subject.subjectDesc,"</div></a><ul>");
                                                                                    var rubItemIndex=0;
                                                                                   for (let rubrique of subject.rubriques) {
                                                                                   rubItemIndex++;
                                                                                                   console.log("RBC---------------------------------------------------------------");
                                                                                                               console.log("Begin copy to liList RubriqueModel");

                                                                                                               liList=liList.concat("<li>  <a  href=\"/medias/rubDisplay?rubId=",rubrique.entityId,"\" title='",rubrique.rubriqueDesc, "'><div>",rubrique.rubriqueDesc,"</div></a></li>");

                                                                                                    //On limite à 3 rubriques par ligne
                                                                                                                        if(rubItemIndex>=2&&rubItemIndex % 2 == 0){
                                                                                                                            liList=liList.concat("</ul><ul>");
                                                                                                                        }
                                                                                               }
                                                                                               liList=liList.concat("</ul></li>");

                                                                               //On limite à 3 rubriques par ligne
                                                                              if(subjectItemIndex>=2&&subjectItemIndex % 2 == 0){
                                                                                  liList=liList.concat("</ul><ul>");
                                                                              }

                                                  }


                liList=liList.concat("</ul></div></div></div></li>");
                    //On limite à 11 subjectGrp pa ligne
                    if(itemIndex>=8&&itemIndex % 8 == 0){
                        liList=liList.concat("</ul><ul>");
                    }



                  }
                liList=liList.concat("</ul>");
                  //alert(liList);
                  //console.log(liList);
                  return liList;

               }
                  console.log("End convertSubjectGroupesModelToliList---------------------------------------------------------------" + liList);
              }


}