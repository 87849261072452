import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { User } from '../_models/user';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class UserService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

   getUserByToken() : Observable<User>{

    var user:User=JSON.parse(localStorage.getItem('currentUser'));


        const headersObject = new HttpHeaders({
                                  'Content-Type':  'application/json',
                                  'Access-Control-Allow-Credentials' : 'true',
                                  'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                  'Access-Control-Allow-Headers': '*',
                                  'Authorization': user.id_token
                                })
        //alert('user.id_token=' + user.id_token);
        //alert('user.login=' + user.login);


              const httpOptions = {
                headers: headersObject
              };
              return this.http.get<User>(`${environment.apiUrl}/auth-api/account/`+user.login,httpOptions).pipe(
                                                                                 map((data) => {
                                                                                   if (data) {
                                                                                   //alert(data);
                                                                                   //alert('data.id_token=' + data.id_token);
                                                                                    //                   alert('data.login=' + data.login);
                                                                                   }
                                                                                   return data;
                                                                                 }),
                                                                                 catchError((error) => this.toastService.errorHandlerWithToast(error)),
                                                                               );



        }


         getUser(login) : Observable<User> {
            this.url = `${environment.apiUrl}/auth-api/account/` + login;
            return this.http.get<User>(this.url).pipe(
              map((data) => {
                if (data) {
                //alert(data);
                }
                return data;
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }


}