import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { User } from '../_models/user';
import { Token } from '../_models/token.model';
import { environment } from '@env/environment';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private url:string;


    constructor(private http: HttpClient, private toastService: ToastService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        //this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        //console.log('username'+username);
        //console.log('password'+password);
         var formData: FormData = new FormData();

                formData.append('username', username);
                formData.append('password', password);
        return this.http.post<any>(`${environment.apiUrl}/auth-api/authorize`, formData)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.id_token) {
                user.login = username;
                   // alert("authorize user.id_token = "+user.id_token);
                   // alert("authorize user.login = "+user.login);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);


                }

                return user;
            }));


    }

    register(firstname: string, lastname: string, email: string, telephone: string, password: string, passwordConfirm: string) {
            console.log('firstname'+firstname);
            console.log('lastname'+lastname);
            console.log('email'+email);
            console.log('telephone'+telephone);
             console.log('password'+password);
             console.log('passwordConfirm'+passwordConfirm);
             var formData: FormData = new FormData();

                    formData.append('prenom', firstname);
                    formData.append('nom', lastname);
                    formData.append('email', email);
                    formData.append('telephone', telephone);
                    formData.append('password', password);
                    formData.append('matchingpassword',passwordConfirm);
            return this.http.post<any>(`${environment.apiUrl}/auth-api/register`, formData)
                .pipe(map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.id_token) {
                        //alert(user.id_token);
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);


                    }

                    return user;
                }));


        }

       /* getUser() : Observable<any>{

        var user:User=JSON.parse(localStorage.getItem('currentUser'));


            const headersObject = new HttpHeaders({
                                      'Content-Type':  'application/json',
                                      'Access-Control-Allow-Credentials' : 'true',
                                      'Access-Control-Allow-Origin': '*',
                                      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                      'Access-Control-Allow-Headers': '*',
                                      'Authorization': user.id_token
                                    })
            //alert('user.id_token=' + user.id_token);


                  const httpOptions = {
                    headers: headersObject
                  };
                return this.http.get<any>(`${environment.apiUrl}/auth-api/account`,httpOptions);

            }*/

    logout() {
        // remove user from local storage to log user out
        //alert("logout");
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}