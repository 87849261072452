import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UploadService } from  '../_services/upload.service';

@Component({
  selector: 'mediastore-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;files  = [];
  uploadForm: FormGroup;

    constructor(private formBuilder: FormBuilder,private uploadService: UploadService) { }

  ngOnInit(): void {

  this.uploadForm = this.formBuilder.group({
                      profile: ['', Validators.required]
                  });
  }


       onFileSelect(event) {
           if (event.target.files.length > 0) {
             const file = event.target.files[0];
             this.uploadForm.get('profile').setValue(file);
           }
         }

          onSubmit() {
             const formData = new FormData();
             formData.append('file', this.uploadForm.get('profile').value);


             this.uploadService.upload(formData);


           }

}
