import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModeSecoursService } from '@/tableau-de-bord/service-tableau-de-bord/mode-secours.service';
import { ModeSecoursCourant } from '@/shared/interfaces/mode-secours-courant';
import { RetourUpdateModeSecours } from '@/shared/interfaces/retour-update-mode-secours';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})

export class PopUpComponent implements OnInit {

  updateRequest: ModeSecoursCourant = new ModeSecoursCourant();
  updateResponse: RetourUpdateModeSecours = new RetourUpdateModeSecours();
  dateEffet;
  isModeSecours!: boolean;
  action: string = '';
  hasError!: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<PopUpComponent>, private _snackBar: MatSnackBar,
    private updateModeSecoursService: ModeSecoursService) {
    this.action = data?.action;
  }

  ngOnInit(): void {
    let currentDate = new Date();
    if (this.action === 'Activer') {
      this.isModeSecours = true;
      this.dateEffet = currentDate;
    } else {
      this.isModeSecours = false;
      this.dateEffet = currentDate.setDate(currentDate.getDate() + 1);
    }
  }


  confirmerPopUp() {
    this.updateRequest.modeSecours = this.isModeSecours;
    this.updateModeSecoursService.updateModeSecours(this.updateRequest).subscribe({
      next: (response: RetourUpdateModeSecours) => {
        this.updateResponse = response;
        console.log(this.updateResponse);
        this.dialogRef.close(this.updateResponse);
      },
      error: (error: Error) => {
        console.error(error);
        this.hasError = true;
        this.openErrorSnackBar();
      }
    });

  }

  annulerPopUp() {
    this.dialogRef.close();
  }

  openErrorSnackBar() {
    this._snackBar.open('Une erreur technique est survenue, veuillez réessayer ultérieurement svp.', 'Fermer', {
      duration: 30000,
      verticalPosition: 'top',
      panelClass: 'errorSnack'
    });
  }

}
