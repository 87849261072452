import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ToastService} from '../_services/toast.service';
import { RubriqueModel } from '../_models/rubrique.model';

@Injectable({ providedIn: 'root' })
export class RubriquesService {

    constructor(private http: HttpClient,private toastService: ToastService) { }
        /* findFirstRubriqueBySubjectEntityId : Observable<RubriqueModel> {

                                                        return this.http.get<RubriqueModel>(`${environment.apiUrl}/api/rubDisplay/`+rubId).pipe(
                                                          map((data) => {
                                                            if (data) {
                                                            }
                                                            return data;
                                                          }),
                                                          catchError((error) => this.toastService.errorHandlerWithToast(error)),
                                                        );
                                                      }

         getRubriqueById(rubId) : Observable<RubriqueModel> {

            return this.http.get<RubriqueModel>(`${environment.apiUrl}/api/rubDisplay/`+rubId).pipe(
              map((data) => {
                if (data) {


                }
                return data;
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }
*/


}