import { Component, OnInit } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";

export const MY_FORMATS_DATE = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
  selector: 'app-onglet-slides',
  templateUrl: './onglet-slides.component.html',
  styleUrls: ['./onglet-slides.component.scss'],
    providers: [

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATE},
    ],
})
export class OngletSlidesComponent implements OnInit {
    public rangEtapeEncours = 3;


  constructor( ) { }

  ngOnInit(): void {

  }


}
