import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.scss']
})
export class TableauDeBordComponent implements OnInit {

  constructor(private readonly _router: Router) { }

  ngOnInit(): void {
      this._router.navigate(['/tableau-de-bord/onglet-cours']);
  }

}
