import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mediastore-annonces-formation',
  templateUrl: './annonces-formation.component.html',
  styleUrls: ['./annonces-formation.component.scss']
})
export class AnnoncesFormationComponent implements OnInit {

  @Input() annonces:Array<{}>;

    constructor() { }

    ngOnInit(): void {
    //alert("artComponent ==> article ="+this.articles);
    if(this.annonces){
    console.log("Annonces Ok !!!! ==> "+ this.annonces);
    }
    }



}
