import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mediastore-youtube-mediaplayer',
  templateUrl: './youtube-mediaplayer.component.html',
  styleUrls: ['./youtube-mediaplayer.component.scss']
})
export class YoutubeMediaplayerComponent implements OnInit {

//@Input() videoId;

  constructor() { }

  ngOnInit(): void {
       const tag = document.createElement('script');
           tag.src = 'https://www.youtube.com/iframe_api';
           document.body.appendChild(tag);
  }

}
