  import { Component, OnInit, Input } from '@angular/core';
  import { PlayList } from '../_models/playlist';
  import { PageDirection } from '../_models/pageDirectionEnum';
  import { PlaylistService } from '../_services/playlist.service';

@Component({
  selector: 'mediastore-youtube-playlist',
  templateUrl: './youtube-playlist.component.html',
  styleUrls: ['./youtube-playlist.component.scss']
})
export class YoutubePlaylistComponent implements OnInit {


    @Input() youtubePlaylist: PlayList;

    PageDirection = PageDirection;
    finding = false;
    error = false;

    constructor(private playListService: PlaylistService) { }

    ngOnInit() {
    }

    movePage(direction: PageDirection) {
      this.finding = true;
      this.error = false;
      this.playListService.movePage(this.youtubePlaylist, direction)
        .subscribe(playList => {
          this.youtubePlaylist = playList;
          this.finding = false;
        }, err => {
          this.finding = false;
          this.error = true;
          console.log(err);
        });
    }

  }
