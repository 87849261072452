import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { User } from '../_models/user';
import { Router, ActivatedRoute } from '@angular/router';
import {MatDialogModule,MatDialogRef,MatDialogConfig} from '@angular/material/dialog';
import { AuthenticationService } from '../_services/authentication.service';
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';


@Component({
  selector: 'mediastore-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
    providers: [
      {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' }},
      { provide: MatDialogRef, useValue: {} }
    ]
})
export class RegistrationFormComponent implements OnInit {
@Output() submitClicked = new EventEmitter<any>();
registrationForm: FormGroup;
 loading = false;
 isLoggedIn:boolean;
 currentUser: User;
        submitted = false;
        returnUrl: string;
        error = '';

  constructor( public dialogRef: MatDialogRef<RegistrationFormComponent>,private route: ActivatedRoute,private router: Router,private authenticationService: AuthenticationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
   this.registrationForm = this.formBuilder.group({
                      nom: ['', Validators.required],
                      prenom: ['', Validators.required],
                      email: ['', Validators.required],
                      telephone: ['', Validators.required],
                      password: ['', Validators.required],
                      confirmPassword: ['', Validators.required],
                  });
                   this.isLoggedIn=this.currentUser!=null;
  }

   // convenience getter for easy access to form fields
            get f() { return this.registrationForm.controls; }

   onSubmit() {
                this.submitted = true;

                // stop here if form is invalid
                if (this.registrationForm.invalid) {
                    return;
                }

                this.loading = true;
                this.authenticationService.register(this.f.nom.value, this.f.prenom.value, this.f.email.value,this.f.telephone.value,this.f.password.value,this.f.confirmPassword.value)
                    .pipe(first())
                    .subscribe(
                        data => {
                        this.loading = true;
                        if(this.registrationOk(data)){
                          this.isLoggedIn=true;
                          this.submitClicked.emit(this.isLoggedIn);
                          this.router.navigate(['home']);
                           close();

                        }else{
                            this.error = "Echec d'inscription";
                        }

                          this.loading = false;

                        },
                        error => {
                        console.log(error);
                            this.error = error;
                            this.loading = false;
                        })
                        ;
            }

 registrationOk(data):boolean{
            var regOk:boolean=data.id_token!=null;
            return regOk;
          }

          close(){
                                this.dialogRef.close();
                                }

                                /*dialogRef.afterClosed().subscribe(this.isLoggedIn => {
                                  console.log(`Registration form sent: this.isLoggedIn}`);
                                });*/

}
