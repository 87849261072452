import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mediastore-carrousels',
  templateUrl: './carrousels.component.html',
  styleUrls: ['./carrousels.component.scss']
})
export class CarrouselsComponent implements OnInit {

@Input() slides;
 currentSlide = 0;

  constructor() { }

  onPreviousClick() {
      const previous = this.currentSlide - 1;
      this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
      console.log("previous clicked, new current slide is: ", this.currentSlide);
    }

    onNextClick() {
      const next = this.currentSlide + 1;
      this.currentSlide = next === this.slides.length ? 0 : next;
      console.log("next clicked, new current slide is: ", this.currentSlide);
    }

  ngOnInit(){

  }

}
