import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { SubjectModel } from '../_models/subject.model';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class SubjectService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

   /* getUser() : Observable<any>{

    var user:User=JSON.parse(localStorage.getItem('currentUser'));


        const headersObject = new HttpHeaders({
                                  'Content-Type':  'application/json',
                                  'Access-Control-Allow-Credentials' : 'true',
                                  'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                  'Access-Control-Allow-Headers': '*',
                                  'Authorization': user.id_token
                                })
        //alert('user.id_token=' + user.id_token);


              const httpOptions = {
                headers: headersObject
              };
            return this.http.get<any>(`${environment.apiUrl}/auth-api/account`,httpOptions);

        }*/


         getSubjectById(subjId) : Observable<SubjectModel> {

            return this.http.get<SubjectModel>(`${environment.apiUrl}/api/subjects/`+subjId).pipe(
              map((data) => {
                if (data) {

                 console.log("ArtB---------------------------------------------------------------");

                 console.log("Begin SubjectModel");
                  
                  console.log("ArtE---------------------------------------------------------------");


                }
                return data;
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }



}