import { Component, OnInit ,  ViewChild, Renderer2 } from '@angular/core';


@Component({
  selector: 'mediastore-material-video',
  templateUrl: './material-video.component.html',
  styleUrls: ['./material-video.component.scss']
})
export class MaterialVideoComponent implements OnInit {

   @ViewChild('video2') matVideo: any;
    video: HTMLVideoElement;

    constructor(private renderer: Renderer2) { }

    ngOnInit(): void {
      this.video = this.matVideo.getVideoTag();

      // Use Angular renderer or addEventListener to listen for standard HTML5 video events

      this.renderer.listen(this.video, 'ended', () => console.log('video ended'));
      this.video.addEventListener('ended', () => console.log('video ended'));
    }

}
