import { ViewChild,Component, OnInit , Input, Output, EventEmitter,ElementRef } from '@angular/core';

@Component({
  selector: 'mediastore-media-edit-playlist',
  templateUrl: './media-edit-playlist.component.html',
  styleUrls: ['./media-edit-playlist.component.scss']
})
export class MediaEditPlaylistComponent implements OnInit {

  constructor() {


  }

  ngOnInit(): void {
  //this.deleteMediaInputRef.nativeElement.id=this.currentVideo.entityId;
   this.mediasLoaded.emit(this.videos);
   this.deleteVideos = new Array<{}>();
  }

   @Input() videos: Array<{}>;
   deleteVideos: Array<{}>;
          @Output() videoClicked = new EventEmitter<Object>();
          @Output() mediasLoaded = new EventEmitter<Object>();
           @Output() mediasDeleted = new EventEmitter<Object>();
          @Input() currentVideo;

          onVideoClick(video) {
            this.videoClicked.emit(video);
            this.currentVideo = video;

          }

          onDeleteVideoClick(video){
            this.deleteVideos.push(video);
            this.mediasDeleted.emit(this.deleteVideos);

            for (let video of this.deleteVideos) {
                                          	console.log("DELETE VIDEO---------------------------------------------------------------");
                                          	//alert("video==>"+video);

                                                console.log("END DELETE VIDEO---------------------------------------------------------------");
                                          	}
          }

}
