import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class UploadService {

    SERVER_URL: string = `${environment.apiUrl}/file-api/uploadUserFile`;
    //SERVER_URL: string = "http://localhost:8082/investia/upload";ng
    constructor(private httpClient: HttpClient) { }

    public upload(formData) {

      return this.httpClient.post<any>(this.SERVER_URL, formData, {
         reportProgress: true,
         observe: 'events'
      })
      .subscribe(
                       (res) => console.log(res),
                       (err) => console.log(err)
                     );
   }


}