import { Component, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'mediastore-user-cam',
  templateUrl: './user-cam.component.html',
  styleUrls: ['./user-cam.component.scss']
})
export class UserCamComponent implements OnDestroy{

    @ViewChild('video1', {static: true}) video: ElementRef<HTMLVideoElement>;


    constructor(@Inject(PLATFORM_ID) private _platform: Object) {}

    onStart(){
      if(isPlatformBrowser(this._platform) && 'mediaDevices' in navigator) {
        navigator.mediaDevices.getUserMedia({video: true}).then((ms: MediaStream) => {
          const _video = this.video.nativeElement;
          _video.srcObject = ms;
          _video.play();
        });
      }
    }

    onStop() {
      this.video.nativeElement.pause();
      (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
      this.video.nativeElement.srcObject = null;
    }

    ngOnDestroy() {
      (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
    }


}
