import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import {FormationService} from '../_services/formation.service'
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'mediastore-topics-formation',
  templateUrl: './topics-formation.component.html',
  styleUrls: ['./topics-formation.component.scss']
})
export class TopicsFormationComponent implements OnInit {

  @Input() topics:Array<{}>;
  @Output() formationAnnonces:EventEmitter<Array<{}>> = new EventEmitter<Array<{}>>();
  currentAnnonces:Array<{}>;


    constructor(private formationsService:FormationService, private route:ActivatedRoute) {
    }

    ngOnInit(): void {
    //alert("artComponent ==> article ="+this.articles);

    }

    annoncesDisplay (topicId){
      console.log('HEAD COMP  - annoncesDisplay?topicId='+topicId);
        this.formationsService.findByTopicEntityId(topicId).subscribe(y =>

             {this.currentAnnonces = y;
                 console.log("this.currentAnnonces="+this.currentAnnonces);
                             this.formationAnnonces.emit(this.currentAnnonces);
                 }
             );
      }




}
