import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ServiceAgence } from "@/tableau-de-bord/service-tableau-de-bord/service-agence";
//import { NgxSpinnerService } from "ngx-spinner";
//import { CookieService } from "ngx-cookie-service";
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * Agence Table with filtering & sorting
 */
@Component({
  selector: 'app-onglet-quizz',
  templateUrl: './onglet-quizz.component.html',
  styleUrls: ['./onglet-quizz.component.scss']
})
export class OngletQuizzComponent implements OnInit {


  public rangEtapeEncours = 1;


    constructor(private formBuilder: FormBuilder,
              public snackBar: MatSnackBar) {
  }

  ngOnInit(): void {

  }


    showError(message: string): void {
        let sb = this.snackBar.open(message, 'X', {
            duration: 15000,
            verticalPosition: "top", // Allowed values are  'top' | 'bottom'
            horizontalPosition: "start", // Allowed values are 'start' | 'center' | 'end' | 'left' |
            panelClass: ["custom-style"]
        });
        sb.onAction().subscribe(() => {
            sb.dismiss();
        });
    }

    onPaginateChange(data) {
        //this.sortedData = this.totalAgencesConfig.slice(0, data.pageSize);
    }
}
