import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mediastore-dateslider',
  templateUrl: './dateslider.component.html',
  styleUrls: ['./dateslider.component.scss']
})
export class DatesliderComponent implements OnInit {

  title = 'appBootstrap';

  model:any;

  constructor() { }

  ngOnInit(): void {
  }

}
