import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { User } from '../_models/user';
import { MediaModel } from '../_models/media-model';
import { PlayListModel } from '../_models/playlist-model';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class UserMediasService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

   /* getUser() : Observable<any>{

    var user:User=JSON.parse(localStorage.getItem('currentUser'));


        const headersObject = new HttpHeaders({
                                  'Content-Type':  'application/json',
                                  'Access-Control-Allow-Credentials' : 'true',
                                  'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                                  'Access-Control-Allow-Headers': '*',
                                  'Authorization': user.id_token
                                })
        //alert('user.id_token=' + user.id_token);


              const httpOptions = {
                headers: headersObject
              };
            return this.http.get<any>(`${environment.apiUrl}/auth-api/account`,httpOptions);

        }*/


         getUserPlaylists() : Observable<PlayListModel[]> {
         var url:string=`${environment.apiUrl}/api/playLists`;
         //alert("getUserPlaylists() ");
         console.log("getUserPlaylists() ");
            return this.http.get<PlayListModel[]>(url).pipe(
              map((data) => {
                if (data) {
                 for (let playListModel of data) {
                    this.logData(playListModel);
                  }

                }
                return data;
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }

          logData(playListModel){

                             console.log("PB---------------------------------------------------------------");
                             console.log("Begin playListModel");
                              console.log(playListModel.playListTitle);
                              	console.log(playListModel.playListIconUrl);
                              	console.log(playListModel.playListFolder);
                              	console.log(playListModel.playListDateDebut);
                              	console.log(playListModel.playListDateFin);
                              	console.log(playListModel.position);
                              	for (let mediaModel of playListModel.medias) {
                              	console.log("MB---------------------------------------------------------------");
                              	    console.log("Begin mediaModel");
                              	        console.log(mediaModel.mediaTitle);
                                        console.log(mediaModel.mediaUrl);
                                    	console.log(mediaModel.mediaDate);
                                    	console.log(mediaModel.position);
                                    console.log("End mediaModel");
                                    console.log("ME---------------------------------------------------------------");
                              	}
                             console.log("End playListModel");
                              console.log("PE---------------------------------------------------------------");


          }

          getUserPlaylistById(playlistId) : Observable<PlayListModel> {
                  var url:string=`${environment.apiUrl}/api/playLists/edit/` + playlistId;
                    //alert("getUserPlaylistById url="+url)
                     return this.http.get<PlayListModel>(url).pipe(
                       map((data) => {
                         if (data) {
                         this.logData(data);

                         }
                         return data;
                       }),
                       catchError((error) => this.toastService.errorHandlerWithToast(error)),
                     );
                   }




                                      updateUserPlaylist(playListId: string,playListTitle: string, playListFolder: string, playListOrdre: string, files:any, detetedMediasIds:any) : Observable<any> {
                                              console.log('update playListId='+playListId);
                                              console.log('update playListTitle='+playListTitle);
                                              console.log('update playListFolder='+playListFolder);
                                              console.log('update playListOrdre='+playListOrdre);
                                               var formData: FormData = new FormData();

                                                      formData.append('playListId', playListId);
                                                      formData.append('playListTitle', playListTitle);
                                                      formData.append('playListFolder', playListFolder);
                                                      formData.append('playListOrdre', playListOrdre);
                                                      formData.append('files', files);
                                                      formData.append('detetedMediasIds', detetedMediasIds);
                                              return this.http.post<any>(`${environment.apiUrl}/playLists/update`, formData)
                                                  .pipe(map(() => {

                                                  }));


                                          }


}