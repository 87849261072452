import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@/_services/authentication.service';

@Component({ templateUrl: './sante.component.html' })
export class SanteComponent implements OnInit {
     constructor(

        ) { }

        ngOnInit() {

        }

}
