import { Component, ViewChild, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {loadStripe} from '@stripe/stripe-js';
import {Stripe} from '@stripe/stripe-js';
import {StripeService} from '../_services/stripe.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'mediastore-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

@ViewChild('message') message: any;
checkoutForm: FormGroup;
loading = false;
        submitted = false;
        error = '';
clientSecret:string;
title = "angular-stripe";
priceId = "price_1LwvjEHhJRtp5GtvhMS4hfKu";
product = {
  title: "Formation finance",
  subTitle: "Apprendre la bourse",
  description:
    "Dans cette formation on introduit les concepts fondamentaux qui permettent de réussir à investir en boursecd $.",
  price: 800,
};
quantity = 1;
 playlist:Array<{}>;
 currentVideo:any;

// Set your publishable key: remember to change this to your live publishable key in production
// See your keys here: https://dashboard.stripe.com/apikeys


  constructor(private stripeService:StripeService, private formBuilder: FormBuilder) {
   }

  ngOnInit(): void {
    this.checkoutForm = this.formBuilder.group({
                          priceId: ['priceId', Validators.required]

                      });

                      this.playlist = [
                                                              {
                                                                mediaTitle: 'Friends-Amii-Stewart',
                                                                mediaUrl:
                                                                  '/medias/assets/videos/amy-stewart/Friends-Amii-Stewart.mp4',
                                                                thumbnail:
                                                                  '/medias/assets/videos/denzel-washington_jpg_200x380_q95.JPG',
                                                                  lyrics_p1 : 'Come on my friend and leave your caution to the wind',lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',lyrics_p3 : 'I know we used to keep that feeling out of sight',lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                  lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                  lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                  lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                  lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                  lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                  lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                  lyrics_p13 : 'And let us stick together and make it happen',
                                                                  lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                  lyrics_p15 : 'I won\'t think it over again',
                                                                  lyrics_p16 : 'Je n\'y repenserai plus',
                                                                  lyrics_p17 : 'Take you to the top',
                                                                  lyrics_p18 : 'Te prendre au sommet',
                                                                  lyrics_p19 : 'Tell me every hidden thought',
                                                                  lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                  lyrics_p21 : 'Come on let me love you again',
                                                                  lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                  lyrics_p23 : 'Do the best I can',
                                                                  lyrics_p24 : 'Faire du mieux que je peux',
                                                                  lyrics_p25 : 'Make me tremble with your breath',
                                                                  lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                  lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                  lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                  lyrics_p29 : 'I wanna love your body till the morning light',
                                                                  lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                  lyrics_p31 : 'Ooh watch me my friend',
                                                                  lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                  lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                  lyrics_p34 : 'Je te sens atteindre mon esprit',
                                                                  lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                  lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                  lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                  lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                  lyrics_p39 : 'But everything it seems to be the same',
                                                                  lyrics_p40 : 'Mais tout semble être pareil',
                                                                  lyrics_p41 : 'I want you where you are now',
                                                                  lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                  lyrics_p43 : 'Don\'t wanna lose you',
                                                                  lyrics_p44 : '',

                                                                  show_lyrics : 'false',
                                                                  tracks : [
                                                                      {kind : 'captions',
                                                                       label : 'English',
                                                                        srclang : 'en',
                                                                         src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                         mode : 'showing'},
                                                                      {
                                                                      kind : 'sous-titres',
                                                                   label : 'French',
                                                                    srclang : 'fr',
                                                                     src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                     mode : 'showing'
                                                                      }
                                                                  ]



                                                              },
                                                              {
                                                                                                                              mediaTitle: 'CiscoWebexEvents2021-02-10-14-02-48',
                                                                                                                              mediaUrl:
                                                                                                                                '/medias/assets/videos/bourse/Cisco Webex Events 2021-02-10 14-02-48.mp4',
                                                                                                                              thumbnail:
                                                                                                                                '/medias/assets/videos/denzel-washington_jpg_200x380_q95.JPG',
                                                                                                                                lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                lyrics_p17 : 'Take you to the top',
                                                                                                                                lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                lyrics_p21 : 'Come on let me love you again',
                                                                                                                                lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                lyrics_p23 : 'Do the best I can',
                                                                                                                                lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                lyrics_p34 : 'Je te sens atteindre mon esprit',
                                                                                                                                lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                lyrics_p41 : 'I want you where you are now',
                                                                                                                                lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                lyrics_p44 : '',

                                                                                                                                show_lyrics : 'false',
                                                                                                                                tracks : [
                                                                                                                                    {kind : 'captions',
                                                                                                                                     label : 'English',
                                                                                                                                      srclang : 'en',
                                                                                                                                       src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                       mode : 'showing'},
                                                                                                                                    {
                                                                                                                                    kind : 'sous-titres',
                                                                                                                                 label : 'French',
                                                                                                                                  srclang : 'fr',
                                                                                                                                   src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                    mode : 'showing'
                                                                                                                                     }
                                                                                                                                 ]



                                                                                                                             },

                                                                                                                             {
                                                                                                                                                                                                                                                           mediaTitle: 'Cisco Webex Events 2021-02-11 15-03-54',
                                                                                                                                                                                                                                                           mediaUrl:
                                                                                                                                                                                                                                                             '/medias/assets/videos/bourse/CiscoWebexEvents2021-02-11-15-03-54.mp4',
                                                                                                                                                                                                                                                           thumbnail:
                                                                                                                                                                                                                                                             '/medias/assets/videos/denzel-washington_jpg_200x380_q95.JPG',
                                                                                                                                                                                                                                                             lyrics_p1 : 'Come on my friend and leave your caution to the wind',
                                                                                                                                                                                                                                                             lyrics_p2 : 'Viens mon ami et laisse ta prudence au vent',
                                                                                                                                                                                                                                                             lyrics_p3 : 'I know we used to keep that feeling out of sight',
                                                                                                                                                                                                                                                             lyrics_p4 : 'Je sais que nous gardions ce sentiment hors de vue',
                                                                                                                                                                                                                                                             lyrics_p5 : 'It\'s getting stronger, I feel it burning in my mind',
                                                                                                                                                                                                                                                             lyrics_p6 : 'Ça devient plus fort, je le sens brûler dans mon esprit',
                                                                                                                                                                                                                                                             lyrics_p7 : 'Slowly teasing me it\'s growing deep inside',
                                                                                                                                                                                                                                                             lyrics_p8 : 'Me taquine lentement, elle grandit profondément à l\'intérieur',
                                                                                                                                                                                                                                                             lyrics_p9 : 'Come on and lay your tender lips down on my skin',
                                                                                                                                                                                                                                                             lyrics_p10 : 'Viens poser tes lèvres tendres sur ma peau',
                                                                                                                                                                                                                                                             lyrics_p11 : 'Show me how sweet and easy it can be',
                                                                                                                                                                                                                                                             lyrics_p12 : 'Montre-moi à quel point cela peut être doux et facile',
                                                                                                                                                                                                                                                             lyrics_p13 : 'And let us stick together and make it happen',
                                                                                                                                                                                                                                                             lyrics_p14 : 'Et restons enlacés et faisons que cela arrive',
                                                                                                                                                                                                                                                             lyrics_p15 : 'I won\'t think it over again',
                                                                                                                                                                                                                                                             lyrics_p16 : 'Je n\'y repenserai plus',
                                                                                                                                                                                                                                                             lyrics_p17 : 'Take you to the top',
                                                                                                                                                                                                                                                             lyrics_p18 : 'Te prendre au sommet',
                                                                                                                                                                                                                                                             lyrics_p19 : 'Tell me every hidden thought',
                                                                                                                                                                                                                                                             lyrics_p20 : 'Dis-moi chaque pensée cachée',
                                                                                                                                                                                                                                                             lyrics_p21 : 'Come on let me love you again',
                                                                                                                                                                                                                                                             lyrics_p22 : 'Allez laisse-moi t\'aimer à nouveau',
                                                                                                                                                                                                                                                             lyrics_p23 : 'Do the best I can',
                                                                                                                                                                                                                                                             lyrics_p24 : 'Faire du mieux que je peux',
                                                                                                                                                                                                                                                             lyrics_p25 : 'Make me tremble with your breath',
                                                                                                                                                                                                                                                             lyrics_p26 : 'Fais-moi trembler avec ton souffle',
                                                                                                                                                                                                                                                             lyrics_p27 : 'Come on my friend and leave your caution way behind',
                                                                                                                                                                                                                                                             lyrics_p28 : 'Viens mon ami et laisse ta prudence derrière toi',
                                                                                                                                                                                                                                                             lyrics_p29 : 'I wanna love your body till the morning light',
                                                                                                                                                                                                                                                             lyrics_p30 : 'Je veux aimer ton corps jusqu\'à la lumière du matin',
                                                                                                                                                                                                                                                             lyrics_p31 : 'Ooh watch me my friend',
                                                                                                                                                                                                                                                             lyrics_p32 : 'Ooh regarde moi mon ami',
                                                                                                                                                                                                                                                             lyrics_p33 : 'I feel you reachin\' in my mind',
                                                                                                                                                                                                                                                             lyrics_p34 : 'Je te sens atteindre mon esprit',
                                                                                                                                                                                                                                                             lyrics_p35 : 'Though I know we\'ll be in love just for one night',
                                                                                                                                                                                                                                                             lyrics_p36 : 'Même si je sais que nous serons amoureux juste pour une nuit',
                                                                                                                                                                                                                                                             lyrics_p37 : 'I keep on trying to change my point of view again',
                                                                                                                                                                                                                                                             lyrics_p38 : 'Je continue d\'essayer de changer de nouveau mon point de vue',
                                                                                                                                                                                                                                                             lyrics_p39 : 'But everything it seems to be the same',
                                                                                                                                                                                                                                                             lyrics_p40 : 'Mais tout semble être pareil',
                                                                                                                                                                                                                                                             lyrics_p41 : 'I want you where you are now',
                                                                                                                                                                                                                                                             lyrics_p42 : 'Je te veux où tu es maintenant',
                                                                                                                                                                                                                                                             lyrics_p43 : 'Don\'t wanna lose you',
                                                                                                                                                                                                                                                             lyrics_p44 : '',
                                                                                                                                                                                                                                                             lyrics_p45 : '',
                                                                                                                                                                                                                                                             lyrics_p46 : '',
                                                                                                                                                                                                                                                             lyrics_p47 : '',
                                                                                                                                                                                                                                                             lyrics_p48 : '',
                                                                                                                                                                                                                                                             lyrics_p49 : '',
                                                                                                                                                                                                                                                             lyrics_p50 : '',
                                                                                                                                                                                                                                                             lyrics_p51 : '',
                                                                                                                                                                                                                                                             lyrics_p52 : '',
                                                                                                                                                                                                                                                             lyrics_p53 : '',
                                                                                                                                                                                                                                                             lyrics_p54 : '',
                                                                                                                                                                                                                                                             lyrics_p56 : '',
                                                                                                                                                                                                                                                             lyrics_p57 : '',
                                                                                                                                                                                                                                                             lyrics_p58 : '',
                                                                                                                                                                                                                                                             lyrics_p59 : '',
                                                                                                                                                                                                                                                             lyrics_p60 : '',
                                                                                                                                                                                                                                                             lyrics_p61 : '',
                                                                                                                                                                                                                                                             lyrics_p62 : '',
                                                                                                                                                                                                                                                             lyrics_p63 : '',
                                                                                                                                                                                                                                                             lyrics_p64 : '',
                                                                                                                                                                                                                                                             lyrics_p65 : '',
                                                                                                                                                                                                                                                             lyrics_p66 : '',
                                                                                                                                                                                                                                                             lyrics_p67 : '',
                                                                                                                                                                                                                                                             lyrics_p68 : '',
                                                                                                                                                                                                                                                             lyrics_p69 : '',
                                                                                                                                                                                                                                                             lyrics_p70 : '',
                                                                                                                                                                                                                                                             lyrics_p71 : '',
                                                                                                                                                                                                                                                             lyrics_p72 : '',
                                                                                                                                                                                                                                                             lyrics_p73 : '',
                                                                                                                                                                                                                                                             lyrics_p74 : '',
                                                                                                                                                                                                                                                             show_lyrics : 'true',
                                                                                                                                                                                                                                                             tracks : [
                                                                                                                                                                                                                                                                 {kind : 'captions',
                                                                                                                                                                                                                                                                  label : 'English',
                                                                                                                                                                                                                                                                   srclang : 'en',
                                                                                                                                                                                                                                                                    src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                                                                                                    mode : 'showing'},
                                                                                                                                                                                                                                                                 {
                                                                                                                                                                                                                                                                 kind : 'sous-titres',
                                                                                                                                                                                                                                                              label : 'French',
                                                                                                                                                                                                                                                               srclang : 'fr',
                                                                                                                                                                                                                                                                src : '/medias/assets/subtitles/MIB2-subtitles-pt-BR.vtt',
                                                                                                                                                                                                                                                                 mode : 'showing'
                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                              ]



                                                                                                                                                                                                                                                          }


                                                ];
                                                this.currentVideo = this.playlist[0];
  }

  get f() { return this.checkoutForm.controls; }

     onSubmit() {
          this.submitted = true;
            console.log("Payment of " + this.product.price);
          // stop here if form is invalid
          if (this.checkoutForm.invalid) {
              return;
          }

          this.loading = true;
          this.stripeService.checkout(this.f.priceId.value)
              .pipe(first())
              .subscribe(
                  data => {
                    window.location.href = data.checkoutUrl;
                    this.loading = false;
                  },
                  error => {
                  console.log(error);
                      this.error = error;
                      this.loading = false;
                  })
                  ;
      }






  /*chargeCreditCard() {
    let form = document.getElementsByTagName("form")[0];
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber.value,
      exp_month: form.expMonth.value,
      exp_year: form.expYear.value,
      cvc: form.cvc.value
    }, (status: number, response: any) => {
      if (status === 200) {
        let token = response.id;
        this.stripeService.chargeCard(token,this.product.price);
      } else {
        console.log(response.error.message);
      }
    });
  }*/

}
