import { Component, OnInit,Input } from '@angular/core';
import {ArticleService} from '../_services/article.service';
import {ArticleModel} from '../_models/article.model';

@Component({
  selector: 'mediastore-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
@Input() articles:Array<{}>;

  constructor() { }

  ngOnInit(): void {
  //alert("artComponent ==> article ="+this.articles);
  if(this.articles){
  console.log("Articles Ok !!!! ==> "+ this.articles);
  }
  }




}
