import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { TableauDeBordRoutingModule } from './tableau-de-bord-routing.module';
import { TableauDeBordComponent } from './tableau-de-bord.component';
import { OngletVideosComponent } from './onglet-videos/onglet-videos.component';
import { OngletQuizzComponent } from './onglet-quizz/onglet-quizz.component';
import { OngletSlidesComponent } from './onglet-slides/onglet-slides.component';
import { OngletCoursComponent } from './onglet-cours/onglet-cours.component';
import { PopUpComponent } from './onglet-cours/pop-up/pop-up.component';
import { SharedModule } from '@/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import { RailroadComponent } from './railroad/railroad.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  declarations: [
    TableauDeBordComponent,
      OngletVideosComponent,
      OngletQuizzComponent,
      OngletSlidesComponent,
      OngletCoursComponent,
      PopUpComponent,
      RailroadComponent,

  ],
  schemas: [
            CUSTOM_ELEMENTS_SCHEMA
          ],
    imports: [
        TableauDeBordRoutingModule,
        SharedModule,
        MatTabsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSliderModule,
        MatIconModule,
        MatSortModule,
        FormsModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        DragDropModule,
        MatDatepickerModule,
    ],
     exports :[TableauDeBordComponent]
})
export class TableauDeBordModule { }
