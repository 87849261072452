import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'mediastore-media-playlist',
  templateUrl: './media-playlist.component.html',
  styleUrls: ['./media-playlist.component.scss']
})
export class MediaPlaylistComponent implements OnInit {



  constructor() {}
        @Input() videos: Array<{}>;
        @Output() videoClicked = new EventEmitter<Object>();

        currentVideo = {};

        onVideoClick(video) {
          this.videoClicked.emit(video);
          this.currentVideo = video;

        }

        ngOnInit() {

        }



}
