import { Component, OnInit, ViewChild, Input, Renderer2  } from '@angular/core';
import * as $ from "jquery";
import { Subscription } from 'rxjs';
import { ResizeService } from '../../_services/resize.service';
declare var google: any;




@Component({
  selector: 'mediastore-mediaAdsPlayer',
  templateUrl: './mediaAdsPlayer.component.html',
  styleUrls: ['./mediaAdsPlayer.component.scss']
})

 export class MediaAdsPlayerComponent implements OnInit {



      @ViewChild('videoadsplayer') videoElement: any;
      @ViewChild('adContainer') adsElement: any;
      @ViewChild('progress1') seeker: any;
      @ViewChild('progress') progress: any;
      @ViewChild('slider') slider: any;
      @Input() currentVideo;
      @Input() videos: Array<{}>;
      private currentIndex:number=0;

      adDisplayContainer;
      adsLoader;
      adsManager;
      adsInitialized:boolean = false;
      autoplayAllowed:boolean = true;
      autoplayRequiresMuted:boolean = false;
      //google:any;

       // Define a variable to track whether there are ads loaded and initially set it to false
       adsLoaded:boolean = false;

       color = 'primary';
       mode = 'determinate';
       value = 0;
       bufferValue = 75;
       videoDuration;
       videoTimeElapsed;
        showLyrics : boolean;

      video: HTMLVideoElement;
      progressBarStyle = {
        flexBasis: '0%',
      };
      iconClass = 'k-i-play';
      mouseDown = false;
      private resizeSubscription: Subscription;


      constructor(private renderer: Renderer2,private resizeService: ResizeService) {

      }


      loadAds(event) {
                // Prevent this function from running on if there are already ads loaded
                if(this.adsLoaded) {
                  return;
                }
                this.adsLoaded = true;

                // Prevent triggering immediate playback when ads are loading
                event.preventDefault();

                console.log("loading ads");

                // Initialize the container. Must be done via a user action on mobile devices.
                  this.videoElement.nativeElement.play();
                  this.adDisplayContainer.initialize();

                  var width = this.videoElement.nativeElement.clientWidth;
                  var height = this.videoElement.nativeElement.clientHeight;
                  try {
                    this.adsManager.init(width, height, google.ima.ViewMode.NORMAL);
                    this.adsManager.start();
                    console.log("Ads loaded!!!");
                  } catch (adError) {
                    // Play the video without ads, if an error occurs
                    console.log("AdsManager could not be started");
                    this.videoElement.nativeElement.play();
                  }
              }

      togglePlay() {
      if(this.videoElement){
      const method = this.videoElement.nativeElement.paused ? 'play' : 'pause';
     //this.video[method]();

        //alert(this.videoElement);
        if(this.videoElement.nativeElement.paused){
            // Initialize the container. Must be done via a user action where autoplay
            // is not allowed.
            if(this.adDisplayContainer){
                 this.adDisplayContainer.initialize();
                  this.adsInitialized = true;
                  this.playAds();
            }


            //videoContent.play();

           // this.videoElement.nativeElement.muted="true";
            this.videoElement.nativeElement.play();
            this.videoElement.nativeElement.muted="false";

        }else{
            this.videoElement.nativeElement.pause();
        }

                setTimeout(() => this.updateButton(), 10);
        }
      }

      playAds() {
      console.log("Begin playAds");
      // Initialize the container. Must be done via a user action on mobile devices.
        this.videoElement.nativeElement.play();

        try {
        console.log("AdsInitialized? = "+this.adsInitialized);
          if (!this.adsInitialized) {
            this.adDisplayContainer.initialize();
            this.adsInitialized = true;
            console.log("adDisplayContainer initalized");
          }

          if(this.adsManager){
            console.log("this.adsManager.init");
             // Initialize the ads manager. Ad rules playlist will start at this time.
                      this.adsManager.init(640, 360, google.ima.ViewMode.NORMAL);
                      // Call play to start showing the ad. Single video and overlay ads will
                      // start at this time; the call will be ignored for ad rules.
                      this.adsManager.start();
                      console.log("adsManager started");

          }

        } catch (adError) {
        console.log("Error playAds");
          // An error may be thrown if there was a problem with the VAST response.
          //this.videoElement.nativeElement.muted="true";
          throw(adError);
          this.videoElement.nativeElement.play();
        }
      }

      toggleShowLyrics(){
        this.showLyrics = !this.showLyrics;
        //setTimeout(() => this.videoElement.nativeElement.focus());
      }

      updateButton() {
       //if(this.videoElement){
              //alert(this.videoElement);
              var icon :string;
              if(this.videoElement.nativeElement.paused){
                  icon = 'k-i-play' ;
              }else{
                  icon = 'k-i-pause' ;
              }
                this.iconClass = icon;

              //}



      }


     public nextVideo(){
     //CHARGEMENT DE LA PUB AVANT DE LANCER LA NOUVELLE VIDEO
     console.log("nextVideo() : CHARGEMENT DE LA PUB AVANT DE LANCER LA NOUVELLE VIDEO");
         if(this.adsLoader){
         console.log("this.adsLoader.contentComplete()");
         console.log(this.adsLoader);
            this.adsLoader.contentComplete();
         }
         console.log("this.playAds()");
         this.playAds();
          console.log("End this.playAds()");
         if(this.currentIndex!=this.videos.length){
            this.currentIndex++;
         } else{
          this.currentIndex=0;
         }
        this.currentVideo = this.videos[this.currentIndex];

        //this.addTracks();
        this.showLyrics = this.currentVideo.showLyrics;
        setTimeout(() => this.videoElement.nativeElement.focus());
         console.log("this.videoElement.nativeElement.play");
                setTimeout(() => this.videoElement.nativeElement.play());

                var playPromise = this.videoElement.nativeElement.play();

                  if (playPromise !== undefined) {
                    playPromise.then(_ => {
                      // Automatic playback started!
                      // Show playing UI.
                    })
                    .catch(error => {
                      // Auto-play was prevented
                      // Show paused UI.
                    });
                  }

                this.showLyrics = (this.currentVideo.showLyrics=='true');
      }

      public previousVideo(){
               if(this.currentIndex!=0){
                  this.currentIndex--;
               } else{
                this.currentIndex=0;
               }
              this.currentVideo = this.videos[this.currentIndex];

              //Add tracks
              //this.addTracks();
              setTimeout(() => this.videoElement.nativeElement.focus());
                      setTimeout(() => this.videoElement.nativeElement.play());
                      this.showLyrics = (this.currentVideo.showLyrics=='true');
            }


            addTracks(){
                 let tracks = this.currentVideo.tracks;
                  for (var i in tracks) {
                    let track= "<track kind='" + tracks[i].kind +"' label='" + tracks[i].label +"' srclang='" + tracks[i].srclang +"' src='" + tracks[i].src +"' mode='" + tracks[i].mode +"' default>";
                    //alert(track);
                    if(this.videoElement){
                        this.videoElement.nativeElement.insertAdjacentHTML("beforeend", track);
                    }
                  }
            }

      toFullScreen(e) {


        if (this.videoElement.nativeElement.requestFullscreen) {

          this.videoElement.nativeElement.requestFullscreen(e);
        } else if (this.videoElement.nativeElement.mozRequestFullScreen) {

          this.videoElement.nativeElement.mozRequestFullScreen(e);
        } else if (this.videoElement.nativeElement.webkitRequestFullscreen) {

          this.videoElement.nativeElement.webkitRequestFullscreen(e);
        }

      }

      handleVolumeChange(e) {
        const { target } = e;
        const { value } = target;
       // alert("value="+value);
        this.videoElement.nativeElement.volume = value;
      }

      muteVideo(){
        this.videoElement.nativeElement.volume = 0;
        this.slider.nativeElement.value=0;
      }

      unmuteVideo(){
              this.videoElement.nativeElement.volume = 1;
              this.slider.nativeElement.value=1;
            }



      handleProgress() {

        const percent = (this.videoElement.nativeElement.currentTime / this.videoElement.nativeElement.duration) * 100;
        //alert(percent);
        this.seeker.nativeElement.flexBasis = percent +' %';
        this.value=percent;
        this.videoTimeElapsed = new Date(this.videoElement.nativeElement.currentTime * 1000).toISOString().substr(11, 8);
        this.videoDuration = new Date(this.videoElement.nativeElement.duration * 1000).toISOString().substr(11, 8);
      }

      forward(){
        //if(this.value!=100){
                        if(this.value>90){
                              this.value=100;
                        }else{
                              this.value=this.value+10;
                        }
                         this.seeker.nativeElement.flexBasis = this.value +' %';
                         this.videoElement.nativeElement.currentTime = (this.value*this.videoElement.nativeElement.duration)/100;
                   //}
      }

      rewind(){
           //if(this.value!=0){
                if(this.value>10){
                      this.value=this.value-10;
                }else{
                      this.value=0;
                }
                 this.seeker.nativeElement.flexBasis = this.value +' %';
                this.videoElement.nativeElement.currentTime = (this.value*this.videoElement.nativeElement.duration)/100;
           //}

      }

      seek(e:MouseEvent) {

        const { srcElement: progress, offsetX } = e;
        //alert("this.videoElement.nativeElement.duration="+this.videoElement.nativeElement.duration);
       //alert("offsetX  = " + offsetX + " - this.current = " + this.value/100* this.videoElement.nativeElement.duration);
        //const {  offsetWidth } = progress1;

        //alert('progress.offsetWidth='+progress.nativeElement.offsetWidth);
        //alert('offsetX' +offsetX);
        //alert('this.videoElement.nativeElement.offsetWidth='+this.videoElement.nativeElement.offsetWidth);
        //alert('this.videoElement.nativeElement.duration=' +this.videoElement.nativeElement.duration);
          const seekTime = (offsetX /this.videoElement.nativeElement.offsetWidth)* this.videoElement.nativeElement.duration ;
         // alert('seekTime='+seekTime);
           this.videoElement.nativeElement.currentTime = Math.floor(seekTime);
           //this.value=seekTime;

      }

      ngOnInit() {
        //const { nativeElement } = this.videoElement;
        //this.video = nativeElement;
       //this.video =  this.videoElement.nativeElement
        /* if (this.videoElement) {
            this.renderer.listen(this.videoElement, 'ended', () => console.log('video ended'));
         }*/
             //this.videoElement.addEventListener('ended', () => console.log('video ended'));
        //
        //new google();
        this.addTracks();
        this.showLyrics = this.currentVideo.showLyrics;
        setTimeout(() => this.videoElement.nativeElement.focus());
        //this.videoElement.nativeElement.muted="true";
        setTimeout(() => {
                  if(this.videoElement){
                    this.videoElement.nativeElement.play();
                  }


                  });
        /////ADS
        this.resizeSubscription = this.resizeService.onResize$
              .subscribe(size => console.log(size));


        //////
        this.setUpIMA();
        this.togglePlay();



      }

      ngOnDestroy() {
          if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
          }
        }

      ngAfterViewInit() {
      console.log('Focusing Here...');
            setTimeout(() => this.videoElement.nativeElement.focus());
            console.log('Launching play...');

            setTimeout(() => {
                      if(this.videoElement){
                        this.videoElement.nativeElement.play();
                      }


                      });
            console.log('Adding tracks...');
            this.addTracks();
            this.showLyrics = (this.currentVideo.showLyrics=='true');
            console.log('this.showLyrics='+this.showLyrics);
            this.setUpIMA();
            this.togglePlay();
      }

      ngAfterViewChange() {
            console.log('Focusing Here...');
                  setTimeout(() => this.videoElement.nativeElement.focus());
                  setTimeout(() => this.videoElement.nativeElement.play());
                  this.addTracks();
                  this.showLyrics = (this.currentVideo.showLyrics=='true');
                   console.log('this.showLyrics='+this.showLyrics);

            }

      ngOnChanges(changes) {
        if (this.videoElement) {
          this.progressBarStyle.flexBasis = '0%';
          this.value=0;
         // this.videoElement.nativeElement.muted="true";
          setTimeout(() => {
          if(this.videoElement){
            this.videoElement.nativeElement.play();
          }


          });

        }
      }



        /*window.addEventListener('load', function(event) {

          initializeIMA();
          this.videoElement.nativeElement.addEventListener('play', function(event) {
            this.loadAds(event);
          });


        });

        window.addEventListener('resize', function(event) {
          console.log("window resized");

          if(this.adsManager) {
              var width = this.videoElement.nativeElement.clientWidth;
              var height = this.videoElement.nativeElement.clientHeight;
              this.adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
            }
        });*/


        onResize(event) {
          console.log("window resized");

                    if(this.adsManager) {
                        var width = this.videoElement.nativeElement.clientWidth;
                        var height = this.videoElement.nativeElement.clientHeight;
                        this.adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
                      }
        }


        setUpIMA(){
            console.log('setUpIMA');

            if(this.videoElement&&this.videoElement.nativeElement&&this.adsElement&&this.adsElement.nativeElement){
                                       console.log("initializing adDisplayContainer");
                        this.adDisplayContainer = new google.ima.AdDisplayContainer(this.adsElement.nativeElement, this.videoElement.nativeElement);
                                     console.log("this.adDisplayContainer="+this.adDisplayContainer);
                                     console.log("initializing adsLoader");

                                        this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);

                                        this.adsLoader.addEventListener(
                                              google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                                              this.onAdsManagerLoaded,
                                              false);
                                          this.adsLoader.addEventListener(
                                              google.ima.AdErrorEvent.Type.AD_ERROR,
                                              this.onAdError,
                                              false);

                                               console.log("this.adsLoader="+this.adsLoader);

                                        // Let the AdsLoader know when the video has ended
                                        this.videoElement.nativeElement.addEventListener('ended', function() {
                                        if(this.adsLoader){
                                            this.adsLoader.contentComplete();
                                        }

                                        });
                                        console.log("initializing adsRequest");
            var adsRequest = new google.ima.AdsRequest();
                                        adsRequest.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?' +
                                            'sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&' +
                                            'impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&' +
                                            'cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator=';


                                        // Specify the linear and nonlinear slot sizes. This helps the SDK to
                                        // select the correct creative if multiple are returned.

                                            adsRequest.linearAdSlotWidth = this.videoElement.nativeElement.clientWidth;
                                                                                    adsRequest.linearAdSlotHeight = this.videoElement.nativeElement.clientHeight;
                                                                                    adsRequest.nonLinearAdSlotWidth = this.videoElement.nativeElement.clientWidth;
                                                                                    adsRequest.nonLinearAdSlotHeight = this.videoElement.nativeElement.clientHeight / 3;

                                        console.log("adsRequest="+adsRequest);

                                        // Pass the request to the adsLoader to request ads
                                        this.adsLoader.requestAds(adsRequest);
                                        console.log("adsLoader with requestAds="+this.adsLoader);
                                        console.log("Pass the request to the adsLoader to request ads");
             }
                console.log("End SetupIMA");



        }


        adContainerClick(event) {
          console.log("ad container clicked");
          if(this.videoElement.nativeElement.paused) {
            this.videoElement.nativeElement.play();
          } else {
            this.videoElement.nativeElement.pause();
          }
        }


        onAdsManagerLoaded(adsManagerLoadedEvent) {

        if(this.videoElement&&this.videoElement.nativeElement){

                                console.log('begin onAdsManagerLoaded');
                                // Get the ads manager.
                                  var adsRenderingSettings = new google.ima.AdsRenderingSettings();
                                  adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
                                  // Instantiate the AdsManager from the adsLoader response and pass it the video element
                                  // videoContent should be set to the content video element.
                                  this.adsManager =
                                      adsManagerLoadedEvent.getAdsManager(this.videoElement.nativeElement, adsRenderingSettings);
                                  // Mute the ad if doing muted autoplay.
                                  const adVolume = (this.autoplayAllowed && this.autoplayRequiresMuted) ? 0 : 1;
                                  this.adsManager.setVolume(adVolume);

                                  // Add listeners to the required events.
                                  this.adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError);
                                  this.adsManager.addEventListener(
                                      google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, this.onContentPauseRequested);
                                  this.adsManager.addEventListener(
                                      google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
                                      this.onContentResumeRequested);
                                  this.adsManager.addEventListener(
                                      google.ima.AdEvent.Type.ALL_ADS_COMPLETED, this.onAdEvent);

                                  // Listen to any additional events, if necessary.
                                  this.adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, this.onAdEvent);
                                  this.adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, this.onAdEvent);
                                  this.adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, this.onAdEvent);

                                  console.log('end onAdsManagerLoaded');

              }

        }

        onAdError(adErrorEvent) {
         console.log('begin onAdError');
          // Handle the error logging.
          console.log(adErrorEvent.getError());
          if(this.adsManager) {
            this.adsManager.destroy();
          }
          console.log('end onAdError');
        }


        onAdEvent(adEvent) {
          // Retrieve the ad from the event. Some events (e.g. ALL_ADS_COMPLETED)
          // don't have ad object associated.
          console.log('begin onAdEvent');
          var ad = adEvent.getAd();
          switch (adEvent.type) {
            case google.ima.AdEvent.Type.LOADED:
              // This is the first event sent for an ad - it is possible to
              // determine whether the ad is a video ad or an overlay.
              if (!ad.isLinear()) {
                this.videoElement.nativeElement.play();
              }
              break;
          }

          console.log('end onAdEvent');
        }


        onContentPauseRequested() {
         console.log('begin onContentPauseRequested');
          this.videoElement.nativeElement.pause();
          console.log('end onContentPauseRequested');
        }

        onContentResumeRequested() {
        console.log('begin onContentResumeRequested');
          this.videoElement.nativeElement.play();
          console.log('end onContentResumeRequested');
        }

        onAdLoaded(adEvent) {
        console.log('begin onAdLoaded');
          var ad = adEvent.getAd();
          if (!ad.isLinear()) {
            this.videoElement.nativeElement.play();
          }
           console.log('end onAdLoaded');
        }

 }