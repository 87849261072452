import { Component, OnInit,Input,ViewChild, Output, EventEmitter   } from '@angular/core';
import {FormationService} from '../_services/formation.service'
import {DomaineFormationModel} from '../_models/domaine.model';
//import {TopicModel} from '../_models/topic.model';
//import {AnnonceFormationModel} from '../_models/annonceFormation.model';
import { Router, ActivatedRoute } from '@angular/router';
declare const activSmenu: any;
declare const desactSmenu: any;
declare const topDisplay: any;
declare const _gaq: any;

@Component({
  selector: 'mediastore-header-formation',
  templateUrl: './header-formation.component.html',
  styleUrls: ['./header-formation.component.scss']
})
export class HeaderFormationComponent implements OnInit {
    constructor(private formationsService:FormationService,  private route: ActivatedRoute,
                                                                    private router: Router) {


    }
        @ViewChild('formationHtml') formationHtml: any;
          @ViewChild('topicsHtml') topicsHtml: any;
         subjectGroupes:Array<{}>;
         @Output() formationTopics:EventEmitter<Array<{}>> = new EventEmitter<Array<{}>>();
         @Output() formationAnnonces:EventEmitter<Array<{}>> = new EventEmitter<Array<{}>>();
         currentDomaines:Array<{}>;
         currentTopics:Array<{}>;
         currentAnnonces:Array<{}>;
         domaineId:string;
         topicId:string;

         /*static params = {
            domaineId:'domaineId',
            topicId:'topicId'

         };*/


          ngOnInit() {

            this.domaineId=this.route.snapshot.paramMap.get("domaineId");
            this.topicId=this.route.snapshot.paramMap.get("topicId");

            this.route.queryParams.subscribe(params => {
                this.domaineId = params['domaineId'];
                 this.topicId = params['topicId'];
              });

             //console.log(location.search)
             // |> '?q=Angular+NgRx'
            // const params = new URLSearchParams(location.search);
             //console.log('params='+params);

             //this.domaineId = params.get('domaineId');
             //this.topicId = params.get('topicId');

            console.log('>> headerformation domaineId='+this.domaineId);


            if(this.domaineId){
               this.topicsDisplay(this.domaineId);
               console.log('this.topicsDisplay('+this.domaineId+')');
            }

            if(this.topicId){
                          //  this.annoncesDisplay(this.topicId);
                        }

                        this.loadDomaines();
                                if(this.formationHtml){
                                                                this.formationHtml.nativeElement.innerHTML=this.getFormationHtml();
                                                               }

          }

          activSmenu(index){
          alert("activSmenu("+index+")");
            activSmenu(index);
           // this.topicsDisplay (index);
          }

          ViewSessions(annonceId : any){
             let url: string = "/sessions/" + annonceId;
                  this.router.navigateByUrl(url);
               }

           desactSmenu(index){
           alert("desactSmenu("+index+")");
                      desactSmenu(index);
                     // this.topicsDisplay (index);
                    }

           googoo(desc){
           _gaq.push(['_trackEvent', 'Menu', 'Click', desc]);
           }

           register(){
           this.router.navigate(['/register']);
           }



                loadDomaines(){

                        //On ne load la playlist que si on est connecté

                        this.formationsService.getAllDomaines().subscribe(x =>

                               {this.currentDomaines = x;
                               // alert("this.currentDomaines ="+this.currentDomaines);
                           //alert("formationService getDomaines ="+x);
                          // alert("this.formationHtml ="+this.formationHtml);
                              console.log("header formation : formationService getDomaines ="+x);

                               });


                    }

                    topDisplay (domaineId){
                        this.topicsDisplay (domaineId);
                    }

topicsDisplay (domaineId){
  console.log('HEAD COMP  - domDisplay?domaineId='+domaineId);
    this.formationsService.findByDomaineFormationEntityId(domaineId).subscribe(y =>

         {this.currentTopics = y;
             console.log("this.currentTopics="+this.currentTopics);
              console.log("Topics Ok !!!! ==> "+ this.currentTopics);
                     this.topicsHtml.nativeElement.innerHTML=this.getTopicsHtml();
                         this.formationTopics.emit(this.currentTopics);
             }
         );
  }

  getFormationHtml(){
    var htmlFormation = this.formationsService.convertDomainesModelToHtml(this.currentDomaines);

     return htmlFormation;
    }

     getTopicsHtml(){
            var htmlTopics = this.formationsService.convertTopicsModelToHtml(this.currentTopics);
            console.log("htmlTopics ==> "+ htmlTopics);
             return htmlTopics;
            }

            annoncesDisplay (topicId){
                  console.log('HEAD COMP  - annoncesDisplay?topicId='+topicId);
                    this.formationsService.findByTopicEntityId(topicId).subscribe(y =>

                         {this.currentAnnonces = y;
                             console.log("this.currentAnnonces="+this.currentAnnonces);
                                         this.formationAnnonces.emit(this.currentAnnonces);
                             }
                         );
                  }


  }

