import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { JwtInterceptor} from '../_helpers/jwt.interceptor';
import { ErrorInterceptor } from '../_helpers/error.interceptor';
import { CommonModule } from '@angular/common';
import { SessionFormComponent } from '../session-form/session-form.component';
import { SessionFormSemComponent } from '../session-form-sem/session-form.sem.component';
import { SignupComponent } from '../signup/signup.component';
import { TopperComponent } from '../topper/topper.component';
import { TableauDeBordComponent } from '../tableau-de-bord/tableau-de-bord.component';
import { RegistrationFormComponent } from '../registration-form/registration-form.component';
import { CarrouselsComponent } from '../carrousels/carrousels.component';
import { HeaderFormationRoutingModule } from './header-formation.routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// used to create fake backend
import { fakeBackendProvider } from '../_helpers/fake-backend';
import {ToastService} from "../_services/toast.service";
import {ResizeService} from "../_services/resize.service";
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';



@NgModule({
  declarations: [SessionFormComponent,
  SessionFormSemComponent,
  CarrouselsComponent,
  SignupComponent,
           TopperComponent,
           RegistrationFormComponent

  ],
  schemas: [
          CUSTOM_ELEMENTS_SCHEMA
        ],
      providers: [
          { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
          { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

          // provider used to create fake backend
          fakeBackendProvider,
          ToastService,
          ResizeService
      ],
      entryComponents: [SignupComponent,RegistrationFormComponent],
    exports :[TopperComponent,CarrouselsComponent],
  imports: [
  BrowserModule,
  BrowserModule,
          ReactiveFormsModule,
          FormsModule,
          HttpClientModule,
    CommonModule,
    HeaderFormationRoutingModule
  ]
})
export class HeaderFormationModule { }
