import {Component, Input, OnInit} from '@angular/core';
import {Etape} from "@/shared/rail-road/etape";

@Component({
    selector: 'app-railroad',
    templateUrl: './railroad.component.html',
    styleUrls: ['./railroad.component.scss']
})
export class RailroadComponent implements OnInit {


    listEtapes: Array<Etape> = [
        new Etape(0, 'Cours', '/tableau-de-bord/onglet-cours'),
        new Etape(1, 'Slides', '/tableau-de-bord/onglet-slides'),
        new Etape(2, 'Videos', '/tableau-de-bord/onglet-videos'),
        new Etape(3, 'Quizz', '/tableau-de-bord/onglet-quizz')
    ];

    @Input() rangEtapeEnCours: any | undefined;

    constructor() {
    }

    ngOnInit() {
    }

}
