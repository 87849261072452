import { ViewChild, Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'mediastore-ignite-media-player',
  templateUrl: './ignite-media-player.component.html',
  styleUrls: ['./ignite-media-player.component.scss']
})
export class IgniteMediaPlayerComponent implements OnInit {

@ViewChild('screenShotDivEle') div: ElementRef;
  public videoPlayer: any;
  isVpPlaying : boolean;
  screenShotAvail:boolean;

   constructor() {
     this.videoPlayer = {
       options: {
         width: '100%',
         sources: [
         '/mediastore/assets/videos/rdc/politique/GilbertMundela-on-DRC-Elections-Straight-Talk-Africa.mp4',
         '/mediastore/assets/videos/amy-stewart/Friends-Amii-Stewart.mp4',
           '/mediastore/assets/videos/ThomasSankara-1984.mp4'
           ],
           bookmarks: [
                            {
                              title: 'River',
                              description: 'River',
                              time: 13
                            },
                            {
                              title: 'Big Buck Bunny Appears',
                              description: 'Big Buck Bunny Appears',
                              time: 33
                            }
                          ],
                          banners: [{
                            imageUrl: 'https://www.igniteui.com/images/samples/video-player/banner.png',
                            link: 'https://www.infragistics.com/',
                            times: [5, 20, 60],
                            visible: true,
                            closeBanner: true,
                            animate: true,
                            autohide: true,
                            hidedelay: 10000,
                            width: 270,
                            height: 67
                          }],
                          relatedVideos: [
                                   {
                                     imageUrl: 'https://vassallohistory.files.wordpress.com/2013/09/adelphi-rabat.jpg?w=100',
                                     title: 'Quince Presentation Part 1',
                                     css: 'relatedVideosBanner',
                                     sources: ['https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.h264.mp4',
                                       'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.webmvp8.webm',
                                       'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.theora.ogv'
                                     ]
                                   }
                                 ]
       }
     };
   }

   playPause(e) {
      if (this.isVpPlaying) {
        this.videoPlayer.pause();
        this.isVpPlaying = false;
        e.textContent = 'Play';
      } else {
        this.videoPlayer.play();
        this.isVpPlaying = true;
        e.textContent = 'Pause';
      }
    }

  ngOnInit(): void {
  }


  addScreenShot() {
     const cn = this.div.nativeElement;
     const canvas1 = this.videoPlayer.screenshot();
     this.screenShotAvail = true;
     cn.appendChild(canvas1);
   }

   commercials: {
          linkedCommercials: [
            {
              sources: [
                'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_1.h264.mp4',
                'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_1.webmvp8.webm',
                'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_1.theora.ogv'],
              startTime: 20,
              title: 'Quince<br/>Presentation<br/>Part 1',
              link: 'http://www.infragistics.com/'
            },
            {
              sources: [
                'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.h264.mp4',
                'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.webmvp8.webm',
                'https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.theora.ogv'],
              startTime: 100,
              title: 'Quince<br/>Presentation<br/>Part 2',
              link: 'https://www.infragistics.com/'
            }],
          adMessage: {
            hideDelay: 13000
          }
        }



}
