import { ViewChild, Component, ElementRef, Input , OnInit } from '@angular/core';

@Component({
  selector: 'mediastore-marina-media-player',
  templateUrl: './marina-media-player.component.html',
  styleUrls: ['./marina-media-player.component.scss']
})
export class MarinaMediaPlayerComponent implements OnInit {

@ViewChild('.player__video') videoElement : ElementRef;
@ViewChild('.progress') seeker: ElementRef;
@ViewChild('.progress__filled') seeker_filled: ElementRef;
@ViewChild('.player__button[data-control="play"]') playButton: ElementRef;
@ViewChild('.player__slider') sliders: ElementRef;
@ViewChild('.player__button[data-time]') skipButtons: ElementRef;
video: HTMLVideoElement;

constructor() { }

  ngOnInit(): void {

  }

 isMouseDown:boolean = false;

handlePlay() {
if(this.videoElement){
  this.videoElement.nativeElement.paused ? this.videoElement.nativeElement.play() : this.videoElement.nativeElement.pause();
  this.videoElement.nativeElement.paused ? this.playButton.nativeElement.innerHTML = '►' : this.playButton.nativeElement.innerHTML = '❚ ❚';
  }
}

autoSeek(e) {
  let filled_width = this.seeker.nativeElement.offsetWidth / this.videoElement.nativeElement.duration * this.videoElement.nativeElement.currentTime;
  this.seeker_filled.nativeElement.style.width = '${filled_width}px';
}

handleSeeker(e) {
if(this.videoElement){
  this.videoElement.nativeElement.currentTime = e.offsetX * this.videoElement.nativeElement.duration / this.seeker.nativeElement.offsetWidth;
 }
 if(this.seeker_filled){
  this.seeker_filled.nativeElement.style.width = '${e.offsetX}px';
  }
}

handleSkip() {
if(this.videoElement){
  this.videoElement.nativeElement.currentTime +=  new Date();
  }
}

handleSlider() {
  //this.video[this.dataset.control] = this.value;
}

mouseMove(e){
this.isMouseDown && this.handleSeeker(e);
}

mouseDown(){
this.isMouseDown = true;
}

mouseUp(e){
this.isMouseDown = false;
}

/*this.video.nativeElement.addEventListener('click', handlePlay);
this.video.nativeElement.addEventListener('timeupdate', autoSeek);

this.seeker.addEventListener('click', handleSeeker);
this.seeker.addEventListener('mousemove', (e) => this.isMouseDown && handleSeeker(e));
this.seeker.addEventListener('mousedown', () => this.isMouseDown = true);
this.seeker.addEventListener('mouseup', () => this.isMouseDown = false);

this.playButton.addEventListener('click', handlePlay);
this.skipButtons.forEach(skipButton => this.skipButton.addEventListener('click', handleSkip));

this.sliders.forEach(slider => {
  this.slider.addEventListener('change', handleSlider);
  this.slider.addEventListener('mousemove', handleSlider);
});
*/


}
