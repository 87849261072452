import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { SRubriqueModel } from '../_models/srubrique.model';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class SRubriquesService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

        findSrubriquesOfFirstRubriqueOfSubject(subjectId) : Observable<SRubriqueModel[]> {

                                                          return this.http.get<SRubriqueModel[]>(`${environment.apiUrl}/api/srubOfFirstRubOfSubject/`+subjectId).pipe(
                                                            map((data) => {
                                                              if (data) {

                                                               console.log("SfindSrubriquesOfFirstRubriqueOfSubject-B---------------------------------------------------------------");


                                                                console.log("findSrubriquesOfFirstRubriqueOfSubject-E---------------------------------------------------------------");


                                                              }
                                                              return this.convertSRubriquesModelToArray(data);
                                                            }),
                                                            catchError((error) => this.toastService.errorHandlerWithToast(error)),
                                                          );
                                                        }

                                                        convertSRubriquesModelToArray(sRubriques){
                                                                 if(sRubriques&&sRubriques.length!=0){
                                                                     let arrSRubrique = [];
                                                                    for (let srub of sRubriques) {
                                                                    console.log("SRBC---------------------------------------------------------------");
                                                                        console.log("Begin copy SRubriqueModel");
                                                                        var sRubrique = {
                                                                            entityId:srub.entityId,
                                                                            srubriqueCode:srub.srubriqueCode,
                                                                            	srubriqueText:srub.srubriqueText,
                                                                            	srubriqueDesc:srub.srubriqueDesc
                                                                        };
                                                                        arrSRubrique.push(sRubrique);
                                                                         console.log("End copy SRubriqueModel");
                                                                         console.log("SREC---------------------------------------------------------------");
                                                                    }
                                                                    console.log(arrSRubrique);
                                                                    console.log('arrSRubrique='+arrSRubrique);
                                                                    return arrSRubrique;

                                                                 }

                                                                }


         getSRubriqueByRubId(rubId) : Observable<SRubriqueModel[]> {

            return this.http.get<SRubriqueModel[]>(`${environment.apiUrl}/api/rubDisplay/`+rubId).pipe(
              map((data) => {
                if (data) {

                 console.log("SGB---------------------------------------------------------------");
                 console.log("Begin SRubriqueModel");
                  
                  console.log("SGE---------------------------------------------------------------");


                }
                return this.convertSRubriquesModelToArray(data);
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }

          getSRubriqueBySRubId(srubId) : Observable<SRubriqueModel[]> {

                                return this.http.get<SRubriqueModel[]>(`${environment.apiUrl}/api/srubDisplay/`+srubId).pipe(
                                  map((data) => {
                                    if (data) {

                                     console.log("SGB---------------------------------------------------------------");
                                     console.log("Begin SRubriqueModel");

                                      console.log("SGE---------------------------------------------------------------");


                                    }
                                    return this.convertSRubriquesModelToArray(data);
                                  }),
                                  catchError((error) => this.toastService.errorHandlerWithToast(error)),
                                );
                              }



}