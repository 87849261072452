import {ToastrManager} from "ng6-toastr-notifications";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ServiceUtils} from "../utils/service-utils";
import {HttpErrorResponse} from "@angular/common/http";

/**
 * utilitaire pour le toaster
 */
@Injectable()
export class ToastService {

  constructor(private readonly toastr: ToastrManager) {
  }

  /**
   * si message d'erreur => l'afficher sinon message générique
   * @param messageError le message d'erreur
   * @param codeError le code du message d'erreur
   */
  public toastErreur(messageError: string, codeError: string) {
    if (messageError) {
      this.toastr.errorToastr(messageError, 'Erreur ' + codeError);
    } else {
      this.toastr.errorToastr('Une erreur est survenue.', 'Erreur');
    }
  }

  /**
   * Appel les erreurs plus le handler
   * @param error response
   */
  public errorHandlerWithToast(error: HttpErrorResponse): Observable<never> {
    let codeErreur = "";
    let mesageErreur = null;
    if (error.error !== undefined) {
      codeErreur = error.error.code;
      mesageErreur = error.error.libelle;
      this.toastErreur(mesageErreur, codeErreur);
    }
    return ServiceUtils.errorHandler(error);

  }

  /**
    * Affiche le toaster de success
    * @param message
    */
  public toastSuccess(message: string) {
    this.toastr.successToastr(message);
  }

  /**
    * Affiche le toaster d'info
    * @param message
    */
  public toastWarning(message: string) {
    this.toastr.warningToastr(message);
  }
}
