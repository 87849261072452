import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { DomaineFormationModel } from '../_models/domaine.model';
import { SessionFormationModel } from '../_models/session.formation.model';
import { SessionFormationSemModel } from '../_models/session.formation.sem.model';
import { TopicsModel } from '../_models/topics.model';
import { AnnFormModel } from '../_models/annonces.formation.model';
import {ToastService} from '../_services/toast.service';

@Injectable({ providedIn: 'root' })
export class FormationService {

    private url:string;

    constructor(private http: HttpClient,private toastService: ToastService) { }

    findByDomaineFormationEntityId(domaineId) : Observable<DomaineFormationModel[]> {
          console.log("Calling " + `${environment.apiUrl}/formations/topicsOfDom/` + domaineId);
          return this.http.get<TopicsModel>(`${environment.apiUrl}/formations/topicsOfDom/` + domaineId).pipe(
            map((data) => {
             console.log("getTopicsOfDomaine ==>"+domaineId);
              if (data) {

               console.log("Topic Begin---------------------------------------------------------------");

               console.log("Begin TopicModel");

                console.log("Topic End---------------------------------------------------------------");


              }
              return this.convertTopicsModelToArray(data);
            }),
            catchError((error) => this.toastService.errorHandlerWithToast(error)),
          );
        }

        findByTopicEntityId(topicId) : Observable<TopicsModel[]> {
                  console.log("Calling " + `${environment.apiUrl}/formations/annoncesForm/` + topicId);
                  return this.http.get<TopicsModel>(`${environment.apiUrl}/formations/annoncesForm/` + topicId).pipe(
                    map((data) => {
                     console.log("annonces of topic ==>"+topicId);
                      if (data) {

                       console.log("Annonce Begin---------------------------------------------------------------");

                       

                        console.log("Annonce End---------------------------------------------------------------");


                      }
                      return this.convertAnnonceFormationsModelToArray(data);
                    }),
                    catchError((error) => this.toastService.errorHandlerWithToast(error)),
                  );
                }

         getAllDomaines() : Observable<DomaineFormationModel[]> {
            console.log("Calling " + `${environment.apiUrl}/formations/domForm`);
            return this.http.get<DomaineFormationModel>(`${environment.apiUrl}/formations/domForm`).pipe(
              map((data) => {
               console.log("getAllDomaines data ==>"+data);
                if (data) {

                 console.log("Dom Begin---------------------------------------------------------------");

                 console.log("Begin DomaineModel");
                  
                  console.log("Dom End---------------------------------------------------------------");


                }
                return this.convertDomainesModelToArray(data);
              }),
              catchError((error) => this.toastService.errorHandlerWithToast(error)),
            );
          }

          findByAnnonceFormationModelAnnFormId(annonceId) : Observable<SessionFormationModel[]> {
                    console.log("Calling " + `${environment.apiUrl}/formations/sessionsForm/` + annonceId);
                    return this.http.get<SessionFormationModel>(`${environment.apiUrl}/formations/sessionsForm/` + annonceId).pipe(
                      map((data) => {
                       console.log("getSessionsOfAnnonce ==>"+annonceId);
                        if (data) {

                         console.log("Session Begin---------------------------------------------------------------");

                         console.log("Begin SessionModel");

                          console.log("Session End---------------------------------------------------------------");


                        }
                        return this.convertsessFormationsModelToArray(data);
                      }),
                      catchError((error) => this.toastService.errorHandlerWithToast(error)),
                    );
                  }
                  
                  findBySessionFormationModelSessFormIdOrderByOrdreAsc(sessionId) : Observable<SessionFormationModel[]> {
                                      console.log("Calling " + `${environment.apiUrl}/formations/sessionsSemForm/` + sessionId);
                                      return this.http.get<SessionFormationSemModel>(`${environment.apiUrl}/formations/sessionsSemForm/` + sessionId).pipe(
                                        map((data) => {
                                         console.log("getSessionsSemOfSession ==>"+sessionId);
                                          if (data) {
                  
                                           console.log("Session Begin---------------------------------------------------------------");
                  
                                           console.log("Begin SessionModel");
                  
                                            console.log("Session End---------------------------------------------------------------");
                  
                  
                                          }
                                          return this.convertsessFormsModelToArray(data);
                                        }),
                                        catchError((error) => this.toastService.errorHandlerWithToast(error)),
                                      );
                                    }

                      convertDomainesModelToArray(domaineModels){
                                        console.log("domaineModels.length="+domaineModels.length);
                                        if(domaineModels&&domaineModels.length!=0){
                                        console.log("BEGIN DOM---------------------------------------------------------------");

                                            let arrDomaine = [];
                                            var i=1;
                                           for (let domaine of domaineModels) {
                                           var mTId:string="mT";
                                              var mSId:string="mS";
                                              var mouseOverFct:string="activSmenu(";
                                              var domId:string="domaine";
                                              mouseOverFct = mouseOverFct.concat(String(i),")");
                                              console.log("mouseOverFct="+mouseOverFct);


                                              var mouseOutFct:string="desactSmenu(";
                                              mouseOutFct = mouseOutFct.concat(String(i),",'mTCgrey'",")");
                                              console.log("mouseOutFct="+mouseOutFct);

                                               var domaineModel = {
                                                   entityId:domaine.entityId,
                                                   	domFormTitle:domaine.domFormTitle,
                                                   	mTId:mTId.concat(String(i)),
                                                   	mSId:mSId.concat(String(i)),
                                                   	mouseOverFct:mouseOverFct,
                                                   	mouseOutFct:mouseOutFct,
                                                   	domId:domId.concat(domaine.entityId)

                                               };

                                               arrDomaine.push(domaineModel);i++;

                                           }
                                           console.log(arrDomaine);
                                           console.log("End copy DomaineModel");
                                           console.log("END BEGIN DOM---------------------------------------------------------------");
                                           return arrDomaine;

                                        }

                                       }



                                      convertDomainesModelToHtml (domaineModels){
                                            var liList:string="";
                                            console.log("domaineModels.length="+domaineModels.length);
                                            if(domaineModels&&domaineModels.length!=0){
                                                   console.log("BEGIN DOM---------------------------------------------------------------");
                                                   liList = liList.concat("<ul>");

                                                      // let arrDomaine = [];
                                                      var itemIndex=0;
                                                    for (let domaine of domaineModels) {
                                                        itemIndex++;
                                                        liList = liList.concat("<li  id='mT",String(domaine.entityId), "'><a onmouseover='activSmenu(", String(domaine.entityId), ")' onmouseout=\"desactSmenu(",String(domaine.entityId),",'mTCgrey')\" onclick='document.getElementById(\"",String(domaine.domId),"\").click()' title='",domaine.domFormTitle,"'>",domaine.domFormTitle," </a> ");
                                                        liList =liList.concat("<div id='mS", String(domaine.entityId), "' onmouseover='activSmenu(", String(domaine.entityId), ")' onmouseout=\"desactSmenu(",String(domaine.entityId),",'mTCgrey')\" style='display: none;'>");
                                                        liList =liList.concat("<div class='mSmenu_gl' style='width:185px'>");
                                                        liList=liList.concat("<div style='width:500px' >");

                                                        liList=liList.concat("<ul class='mTmenu_ul'>");

                                                    liList=liList.concat("</ul></div></div></div></li>");

                                                    }
                                                  console.log("End copy DomaineModel");
                                                  console.log("END BEGIN DOM---------------------------------------------------------------");
                                                                                      //return arrDomaine;
                                            }
                                             console.log("liList="+liList);
                                             return liList;

                                        }
                                        
                                        convertTopicsModelToHtml (topicsModels){
                                                                                    var liList:string="";
                                                                                    console.log("topicsModels.length="+topicsModels.length);
                                                                                    if(topicsModels&&topicsModels.length!=0){
                                                                                           console.log("BEGIN TOPIC HTML---------------------------------------------------------------");
                                                                                           liList = liList.concat("<ul>");
                                        
                                                                                              // let arrDomaine = [];
                                                                                              var itemIndex=0;
                                                                                            for (let topic of topicsModels) {
                                                                                                itemIndex++;
                                                                                                liList = liList.concat("<li  id='mT",String(topic.entityId), "'><a onmouseover='activSmenu(", String(topic.entityId), ")' onmouseout=\"desactSmenu(",String(topic.entityId),",'mTCgrey')\" onclick='document.getElementById(\"",String(topic.topicId),"\").click()' title='",topic.topicFormTitle,"'>",topic.topicFormTitle," </a> ");
                                                                                                liList =liList.concat("<div id='mS", String(topic.entityId), "' onmouseover='activSmenu(", String(topic.entityId), ")' onmouseout=\"desactSmenu(",String(topic.entityId),",'mTCgrey')\" style='display: none;'>");
                                                                                                liList =liList.concat("<div class='mSmenu_gl' style='width:185px'>");
                                                                                                liList=liList.concat("<div style='width:500px' >");
                                        
                                                                                                liList=liList.concat("<ul class='mTmenu_ul'>");
                                        
                                                                                            liList=liList.concat("</ul></div></div></div></li>");
                                                                                             if(itemIndex>=8&&itemIndex % 8 == 0){
                                                                                                                    liList=liList.concat("</ul><ul>");
                                                                                                                }
                                        
                                                                                            }
                                                                                          console.log("End copy TopicModel");
                                                                                          console.log("END TOPIC HTML---------------------------------------------------------------");
                                                                                                                              //return arrDomaine;
                                                                                    }
                                                                                     console.log("liList="+liList);
                                                                                     return liList;
                                        
                                                                                }

                                        convertTopicsModelToArray(topicModels){
                                                                                console.log("topicModels.length="+topicModels.length);
                                                                                if(topicModels&&topicModels.length!=0){
                                                                                console.log("BEGIN TOPIC---------------------------------------------------------------");
                                        
                                                                                    let arrTopic = [];
                                                                                   for (let topic of topicModels) {
                                                                                        var topicId:string="topic";
                                                                                       var topicModel = {
                                                                                           entityId:topic.entityId,
                                                                                           	topicFormTitle:topic.topicFormTitle,
                                                                                           	topicId:topicId.concat(topic.entityId)
                                        
                                                                                       };
                                        
                                                                                       arrTopic.push(topicModel);
                                        
                                                                                   }
                                                                                   console.log(arrTopic);
                                                                                   console.log("End copy TopicModel");
                                                                                   console.log("END BEGIN TOPIC---------------------------------------------------------------");
                                                                                   return arrTopic;
                                        
                                                                                }
                                        
                                                                               }
                                                                               
                                                                               convertAnnonceFormationsModelToArray(AnnFormationModels){
                                                                                                                       console.log("AnnFormationModels.length="+AnnFormationModels.length);
                                                                                                                       if(AnnFormationModels&&AnnFormationModels.length!=0){
                                                                               
                                                                                                                          let arrAnnFormPhotos = [];
                                                                               
                                                                                                                           let arrAnnonceFormation = [];
                                                                                                                          for (let AnnFormation of AnnFormationModels) {
                                                                                                                              for (let photo of AnnFormation.photos) {
                                                                                                                                           console.log("RBC---------------------------------------------------------------");
                                                                                                                                                       console.log("Begin copy AnnonceFormationPhotoModel");
                                                                                                                                                       var photoModel = {
                                                                                                                                                              entityId:photo.entityId,
                                                                                                                                                                  annFormPhotoTitle:photo.annFormPhotoTitle,
                                                                                                                                                                  annFormPhotoUrl:photo.annFormPhotoUrl,
                                                                                                                                                                  annFormPhotoDate:photo.annFormPhotoDate
                                                                               
                                                                                                                                                       };
                                                                                                                                                       arrAnnFormPhotos.push(photoModel);
                                                                                                                                       }
                                                                                                                              console.log("arrAnnFormPhotos="+arrAnnFormPhotos);
                                                                                                                          let arrAnnFormVideos = [];
                                                                                                                       for (let video of AnnFormation.videos) {
                                                                                                                                       console.log("RBC---------------------------------------------------------------");
                                                                                                                                                   console.log("Begin copy AnnonceFormationVideoModel");
                                                                                                                                                   var videoModel = {
                                                                                                                                                         entityId:video.entityId,
                                                                                                                                                          annFormVideoTitle:video.annFormPhotoTitle,
                                                                                                                                                          annFormVideoUrl:video.annFormPhotoUrl,
                                                                                                                                                          annFormVideoDate:video.annFormPhotoDate
                                                                               
                                                                                                                                                   };
                                                                                                                                                   arrAnnFormVideos.push(videoModel);
                                                                                                                                   }
                                                                                                                                   console.log("arrAnnFormVideos="+arrAnnFormVideos);
                                                                                                                          console.log("AnnonceFormationModel---------------------------------------------------------------");
                                                                                                                              console.log("Begin copy AnnonceFormationModel");
                                                                                                                              var pays:string="";
                                                                                                                              var AnnFormationModel = {
                                                                                                                                  annFormId:AnnFormation.annFormId,
                                                                                                                                  	annFormTitle:AnnFormation.annFormTitle,
                                                                                                                                  	annFormResume:AnnFormation.annFormResume,
                                                                                                                                  	annFormIntro:AnnFormation.annFormIntro,
                                                                                                                                  	annFormHtmlText:AnnFormation.annFormHtmlText,
                                                                                                                                  	ordre:AnnFormation.ordre,
                                                                                                                                  	dateCrea:AnnFormation.dateCrea,
                                                                                                                                  	dateMaj:AnnFormation.dateMaj,
                                                                                                                                  	photos : arrAnnFormPhotos,
                                                                                                                                  	videos : arrAnnFormVideos

                                                                                                                              };
                                                                               
                                                                                                                              arrAnnonceFormation.push(AnnFormationModel);
                                                                                                                               console.log("annFormId = "+AnnFormationModel.annFormId);
                                                                                                                               console.log("annFormTitle = "+AnnFormationModel.annFormTitle);
                                                                                                                               console.log("AnnonceFormationModel---------------------------------------------------------------");
                                                                                                                          }
                                                                                                                          console.log(arrAnnonceFormation);
                                                                                                                          return arrAnnonceFormation;
                                                                               
                                                                                                                       }
                                                                               
                                                                                                                      }
                                                                                                                      
                                                                                                                      convertsessFormationsModelToArray(sessFormationModels){
                                                                                                                                                                                                                                             console.log("sessFormationModels.length="+sessFormationModels.length);
                                                                                                                                                                                                                                             if(sessFormationModels&&sessFormationModels.length!=0){
                                                                                                                                                                                                     
                                                                                                                                                                                                                                                
                                                                                                                                                                                                     
                                                                                                                                                                                                                                                 let arrsessFormation = [];
                                                                                                                                                                                                                                                for (let sessFormation of sessFormationModels) {
                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                console.log("SESS---------------------------------------------------------------");
                                                                                                                                                                                                                                                    console.log("Begin copy sessonceFormationModel");
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                    var sessFormationModel = {
                                                                                                                                                                                                                                                        sessFormId:sessFormation.sessFormId,
                                                                                                                                                                                                                                                        	sessFormTitle:sessFormation.sessFormTitle,
                                                                                                                                                                                                                                                        	sessFormResume:sessFormation.sessFormResume,
                                                                                                                                                                                                                                                        	sessFormIntro:sessFormation.sessFormIntro,
                                                                                                                                                                                                                                                        	sessFormHtmlText:sessFormation.sessFormHtmlText,
                                                                                                                                                                                                                                                        	ordre:sessFormation.ordre,
                                                                                                                                                                                                                                                        	dateCrea:sessFormation.dateCrea,
                                                                                                                                                                                                                                                        	dateMaj:sessFormation.dateMaj,
                                                                                                                                                                                                                                                        	
                                                                                                                      
                                                                                                                                                                                                                                                    };
                                                                                                                                                                                                     
                                                                                                                                                                                                                                                    arrsessFormation.push(sessFormationModel);
                                                                                                                                                                                                                                                     console.log("End copy sessonceFormationModel");
                                                                                                                                                                                                                                                     console.log("SESS---------------------------------------------------------------");
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                console.log(arrsessFormation);
                                                                                                                                                                                                                                                return arrsessFormation;
                                                                                                                                                                                                     
                                                                                                                                                                                                                                             }
                                                                                                                                                                                                     
                                                                                                                                                                                                                                            }
         
         convertsessFormsModelToArray(sessFormModels){
                                                                                                                                                                                                                                                      console.log("sessFormModels.length="+sessFormModels.length);
                                                                                                                                                                                                                                                      if(sessFormModels&&sessFormModels.length!=0){
                                                                                                                                                                                                              
                                                                                                                                                                                                                                                         
                                                                                                                                                                                                              
                                                                                                                                                                                                                                                          let arrsessForm = [];
                                                                                                                                                                                                                                                         for (let sessForm of sessFormModels) {
                                                                                                                                                                                                                                                             
                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                         console.log("SESS SEM---------------------------------------------------------------");
                                                                                                                                                                                                                                                             console.log("Begin copy sessFormModel");
                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                             var sessFormModel = {
                                                                                                                                                                                                                                                                 entityId:sessForm.entityId,
                                                                                                                                                                                                                                                                 	sessFormTitle:sessForm.sessFormTitle,
                                                                                                                                                                                                                                                                 	sessFormResume:sessForm.sessFormResume,
                                                                                                                                                                                                                                                                 	sessFormIntro:sessForm.sessFormIntro,
                                                                                                                                                                                                                                                                 	sessFormHtmlText:sessForm.sessFormHtmlText,
                                                                                                                                                                                                                                                                 	ordre:sessForm.ordre,
                                                                                                                                                                                                                                                                 	dateCrea:sessForm.dateCrea,
                                                                                                                                                                                                                                                                 	dateMaj:sessForm.dateMaj,
                                                                                                                                                                                                                                                                 	
                                                                                                                               
                                                                                                                                                                                                                                                             };
                                                                                                                                                                                                              
                                                                                                                                                                                                                                                             arrsessForm.push(sessFormModel);
                                                                                                                                                                                                                                                              console.log("End copy sessFormModel");
                                                                                                                                                                                                                                                              console.log("SESS---------------------------------------------------------------");
                                                                                                                                                                                                                                                         }
                                                                                                                                                                                                                                                         console.log(arrsessForm);
                                                                                                                                                                                                                                                         return arrsessForm;
                                                                                                                                                                                                              
                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                              
                                                                                                                                                                                                                                                     }
                                                                                                                                                                                                       
                                                                                                                                                                                                                      
                                                                              
                                                                                                                                                              
}