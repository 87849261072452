
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { YouTubePlayerModule } from '@angular/youtube-player';

// Material UI Modules
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastrModule} from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
// used to create fake backend
import { fakeBackendProvider } from './_helpers/fake-backend';
import {ToastService} from "./_services/toast.service";
import {ResizeService} from "./_services/resize.service";
import { AppComponent } from './app.component';


import { JwtInterceptor} from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SportsComponent } from './sports/sports.component';
import { TendancesComponent } from './tendances/tendances.component';
import { SanteComponent } from './sante/sante.component';
import { OpinionComponent } from './opinion/opinion.component';
import { JobsComponent } from './jobs/jobs.component';
import { ItnewsComponent } from './itnews/itnews.component';
import { ImmoComponent } from './immo/immo.component';
import { EvryComponent } from './evry/evry.component';
import { CarsComponent } from './cars/cars.component';
import { EntertainmentComponent } from './entertainment/entertainment.component';
import { RealEstateComponent } from './realestate/realestate.component';
import { CarouselComponent } from './carousel/carousel.component';
import { BusinessComponent } from './business/business.component';
import { FinanceComponent } from './finance/finance.component';
import { AnnoncesComponent } from './annonces/annonces.component';
import { MediaHeaderComponent } from './medias/media-header/media-header.component';
import { MediaplayerComponent } from './medias/mediaplayer/mediaplayer.component';
import { MediaAdsPlayerComponent } from './medias/mediaAdsPlayer/mediaAdsPlayer.component';
import { MediaPlaylistComponent } from './medias/media-playlist/media-playlist.component';
import { UserCamComponent } from './user-cam/user-cam.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatVideoModule } from 'mat-video';
import { MaterialVideoComponent } from './medias/material-video/material-video.component';
import { IgVideoPlayerComponent } from "igniteui-angular-wrappers";
import { IgniteMediaPlayerComponent } from './medias/ignite-media-player/ignite-media-player.component';
import { MarinaMediaPlayerComponent } from './medias/marina-media-player/marina-media-player.component';
import { YoutubeMediaplayerComponent } from './medias/youtube-mediaplayer/youtube-mediaplayer.component';
import { YoutubePlaylistComponent } from './youtube-playlist/youtube-playlist.component';
import { YoutubeSearchComponent } from './youtube-search/youtube-search.component';
import { CarrouselsComponent } from './carrousels/carrousels.component';
import { SignalRtcComponent } from './signal-rtc/signal-rtc.component';
import { RtcListusersComponent } from './rtc-listusers/rtc-listusers.component';
import { WebRTCPeerConnectionComponent } from './web-rtcpeer-connection/web-rtcpeer-connection.component';
import { GoogleRtcComponent } from './google-rtc/google-rtc.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { ArticleComponent } from './article/article.component';
import { UploadComponent } from './upload/upload.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { EditPlayListComponent } from './admin/edit-play-list/edit-play-list.component';
import { MediaEditPlaylistComponent } from './medias/media-edit-playlist/media-edit-playlist.component';
import { HeaderFormationComponent } from './header-formation/header-formation.component';
import { TopicsFormationComponent } from './topics-formation/topics-formation.component';
import { AnnoncesFormationComponent } from './annonces-formation/annonces-formation.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { ProductComponent } from './product/product.component';
import { SuccessComponent } from './success/success.component';
import { FailureComponent } from './failure/failure.component';
import { DatesliderComponent } from './dateslider/dateslider.component';
import { AppRoutingModule } from './app-routing.module';
import { TableauDeBordModule } from './tableau-de-bord/tableau-de-bord.module';
import { SignupComponent } from './signup/signup.component';
import { HeaderFormationModule } from './header-formation/header-formation.module';
import { TopperComponent } from './topper/topper.component';
//import { DialogComponent } from './dialog-component/dialog-component.component';
//import { EditDialogComponent } from './dialog/editdialog/editdialog.component';

//import { TimelineComponent } from './timeline/timeline.component';
//import { TimelineTweetComponent } from './timeline-tweet/timeline-tweet.component';
//import { TheomediaplayerComponent } from './theomediaplayer.component';





@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatVideoModule,
        MatProgressBarModule,
        YouTubePlayerModule,
         MatFormFieldModule,
            MatDialogModule,
            MatInputModule,
            MatButtonModule,
            MatSidenavModule,
            MatCheckboxModule,
            MatToolbarModule,
            MatIconModule,
            ToastrModule.forRoot(),
            TableauDeBordModule,
            HeaderFormationModule,
             AppRoutingModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        SportsComponent,
        TendancesComponent,
        SanteComponent,
        OpinionComponent,
        RealEstateComponent,
        EntertainmentComponent,
        JobsComponent,
        ItnewsComponent,
        ImmoComponent,
        EvryComponent,
        CarsComponent,
        CarouselComponent,
        BusinessComponent,
        AnnoncesComponent,
        MediaHeaderComponent,
        MediaplayerComponent,
        MediaAdsPlayerComponent,
        MediaPlaylistComponent,
        UserCamComponent,
        MaterialVideoComponent,
        IgVideoPlayerComponent,
        IgniteMediaPlayerComponent,
        MarinaMediaPlayerComponent,
        YoutubeMediaplayerComponent,
        YoutubePlaylistComponent,
        YoutubeSearchComponent,
       // CarrouselsComponent,
        HeaderMenuComponent,
        ArticleComponent,
        UploadComponent,
        UploadFilesComponent,
        EditPlayListComponent,
        MediaEditPlaylistComponent,
        HeaderFormationComponent,
        TopicsFormationComponent,
         AnnoncesFormationComponent,
       //  RegistrationFormComponent,
         StripePaymentComponent,
         ProductComponent,
         SuccessComponent,
         FailureComponent,
         DatesliderComponent
         //SignupComponent,
         //TopperComponent
        /*SignalRtcComponent,
        RtcListusersComponent,
        WebRTCPeerConnectionComponent,
        GoogleRtcComponent,
        DialogComponent,
        EditDialogComponent*/
        //,
        //TimelineComponent,
        //TimelineTweetComponent
        //,
        //TheomediaplayerComponent

    ],
    //entryComponents: [SignupComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider,
        ToastService,
        ResizeService
    ],
    bootstrap: [AppComponent]
})



export class AppModule { }