import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SessionFormComponent } from '../session-form/session-form.component';
import { SessionFormSemComponent } from '../session-form-sem/session-form.sem.component';


const routes: Routes = [                                       {
                                                                                                    path: 'sessions/:id',
                                                                                                   component: SessionFormComponent

                                                                                                  },
                                                                                            {
                                                                                                                                                                                                path: 'sessionsem/:sessionId',
                                                                                                                                                                                               component: SessionFormSemComponent

                                                                                                                                                                                              },

                                                                                                  ];

@NgModule({
  //imports: [RouterModule.forChild(routes)],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HeaderFormationRoutingModule { }
