import { Observable, throwError } from 'rxjs';
import {HttpErrorResponse, HttpParams} from "@angular/common/http";

export class ServiceUtils {
  /**
   */
  public static setParameter(parametres: HttpParams, value: any, param: string) {
    if (value){
      parametres=parametres.set(param,value.toString());
    }
    return parametres;
  }

  /**
   * Traitement de l'erreur : affichage du message dans la console et propagation d'une exception.
   * @param error L'erreur à traiter
   * @returns {ErrorObservable} L'exception.
   */
  static errorHandler(error: HttpErrorResponse): Observable<never> {
    const errMsg = error.status ? `${error.status} - ${error.statusText}` : 'Server Error';
    return throwError(errMsg);
  }

}
