import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import {MatDialogRef,MatDialogConfig} from '@angular/material/dialog';
import { AuthenticationService } from '../_services/authentication.service';
import { TopperComponent } from '../topper/topper.component';

@Component({
  selector: 'mediastore-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

loginForm: FormGroup;
loading = false;
submitted = false;
isLoggedIn:boolean;
error = '';


  constructor(public dialogRef: MatDialogRef<TopperComponent>, private formBuilder: FormBuilder,
  private route: ActivatedRoute,
          private router: Router,
          private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
   this.loginForm = this.formBuilder.group({
                      username: ['', Validators.required],
                      password: ['', Validators.required]
                  });
  }

   // convenience getter for easy access to form fields
            get f() { return this.loginForm.controls; }


            onSubmit() {
                this.submitted = true;

                // stop here if form is invalid
                if (this.loginForm.invalid) {
                    return;
                }

                this.loading = true;
                this.authenticationService.login(this.f.username.value, this.f.password.value)
                    .pipe(first())
                    .subscribe(
                        data => {
                        this.loading = true;
                        if(this.connexionOk(data)){
                          this.isLoggedIn=true;
                          //this.submitClicked.emit(this.isLoggedIn);
                         // this.router.navigate(['home']);
                          //this.loadPlayLists();
                          //this.loadDomaines();
                          this.close();
                          //window.opener.location.reload();
                        }else{
                            this.error = "Echec de connexion";
                        }

                         this.loading = false;
                        },
                        error => {
                        console.log(error);
                        alert(error);
                            this.error = error;
                            this.loading = false;
                        })
                        ;
            }

            connexionOk(data):boolean{
                        var conOk:boolean=data.id_token!=null;
                        return conOk;
                      }

                      close(){
                      this.dialogRef.close();
                      }

                     /* dialogRef.afterClosed().subscribe(this.isLoggedIn => {
                        console.log('Signup sent: ' + this.isLoggedIn);
                      });*/

}
